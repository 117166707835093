import navI18n from 'Src/i18n/index.js'
import _tool from 'Src/tool'
import icon_logo from 'Src/assets/images/logo.svg'
import icon_user_info_triangle from 'Src/assets/images/user_info_triangle.svg'
import icon_i18n_triangle from 'Src/assets/images/i18n_triangle.svg'
import icon_promotion_content from 'Src/assets/images/promotion_content.svg'
import icon_directed_data from 'Src/assets/images/directed_data.svg'
import icon_creative_material from 'Src/assets/images/creative_material.svg'
import icon_convert from 'Src/assets/images/convert.svg'
import icon_account_tool from 'Src/assets/images/account_tool.svg'
import icon_optimization_tool from 'Src/assets/images/optimization_tool.svg'
import icon_design_tool from 'Src/assets/images/design_tool.svg'
import icon_clue_management_tool from 'Src/assets/images/clue_management_tool.svg'
import icon_platform_tool from 'Src/assets/images/platform_tool.svg'
import icon_out_link from 'Src/assets/images/out_link.svg'
import slider_icon_product from 'Src/assets/images/sliderbar/product.svg'
import slider_icon_behaviour from 'Src/assets/images/sliderbar/behaviour.svg'
import slider_icon_material_library_pic from 'Src/assets/images/sliderbar/material_library_pic.svg'
import slider_icon_material_library from 'Src/assets/images/sliderbar/material_library.svg'
import slider_icon_word_manage from 'Src/assets/images/sliderbar/word_manage.svg'
import slider_icon_playable from 'Src/assets/images/sliderbar/playable.svg'
import slider_icon_homepage_related_hotsoon from 'Src/assets/images/sliderbar/homepage_related_hotsoon.svg'
import slider_icon_homepage_related_awewe from 'Src/assets/images/sliderbar/homepage_related_awewe.svg'
import slider_icon_user from 'Src/assets/images/sliderbar/user.svg'
import slider_icon_account from 'Src/assets/images/sliderbar/account.svg'
import slider_icon_msg_list from 'Src/assets/images/sliderbar/msg_list.svg'
import slider_icon_msg_subscription from 'Src/assets/images/sliderbar/msg_subscription.svg'
import slider_icon_diagnosis_account from 'Src/assets/images/sliderbar/diagnosis_account.svg'
import slider_icon_diagnosis_ad from 'Src/assets/images/sliderbar/diagnosis_ad.svg'
import slider_icon_fund_manage from 'Src/assets/images/sliderbar/fund_manage.svg'
import slider_icon_activity_coupon from 'Src/assets/images/sliderbar/activity_coupon.svg'

import slider_icon_product_light from 'Src/assets/images/sliderbar_light/product.svg'
import slider_icon_behaviour_light from 'Src/assets/images/sliderbar_light/behaviour.svg'
import slider_icon_material_library_pic_light from 'Src/assets/images/sliderbar_light/material_library_pic.svg'
import slider_icon_material_library_light from 'Src/assets/images/sliderbar_light/material_library.svg'
import slider_icon_word_manage_light from 'Src/assets/images/sliderbar_light/word_manage.svg'
import slider_icon_playable_light from 'Src/assets/images/sliderbar_light/playable.svg'
import slider_icon_homepage_related_hotsoon_light from 'Src/assets/images/sliderbar_light/homepage_related_hotsoon.svg'
import slider_icon_homepage_related_awewe_light from 'Src/assets/images/sliderbar_light/homepage_related_awewe.svg'
import slider_icon_user_light from 'Src/assets/images/sliderbar_light/user.svg'
import slider_icon_account_light from 'Src/assets/images/sliderbar_light/account.svg'
import slider_icon_msg_list_light from 'Src/assets/images/sliderbar_light/msg_list.svg'
import slider_icon_msg_subscription_light from 'Src/assets/images/sliderbar_light/msg_subscription.svg'
import slider_icon_diagnosis_account_light from 'Src/assets/images/sliderbar_light/diagnosis_account.svg'
import slider_icon_diagnosis_ad_light from 'Src/assets/images/sliderbar_light/diagnosis_ad.svg'
import slider_icon_fund_manage_light from 'Src/assets/images/sliderbar_light/fund_manage.svg'
import slider_icon_activity_coupon_light from 'Src/assets/images/sliderbar_light/activity_coupon.svg'

export const SCRIPT_SRC_TYPE = 'toutiao-navigator-sdk'
export const DEFAULT_HEADER_TIPS = '鼠标悬停在上方任意选项，了解更多。工具中找不到的功能请移至资产查找。'

let locale = _tool.queryToJson().locale || 'zh'
navI18n.use(locale)

const primaryNavs = {
  home: {
    name: navI18n.t('primaryNavs.home'),
    link: '/pages/index.html',
    mark: [
      '/overture/index/',
      '/pages/lite/index.html',
      '/pages/index.html'
    ]
  },
  extension: {
    name: navI18n.t('primaryNavs.extension'),
    link: '/overture/data/advertiser/ad/',
    mark: [
      '/overture/data/',
      '/pages/campaign/create.html',
      '/pages/ad/create.html',
      '/pages/ad/batch_copy_ad.html',
      '/overture/creative/',
      '/pages/creative/create.html',
      '/pages/creative/batch_copy_creative.html',
      '/pages/keywords/create.html',
      '/pages/promotion.html'
    ]
  },
  reports: {
    name: navI18n.t('primaryNavs.reports'),
    link: '/pages/reporter/ad-report.html?report_type=advertiser',
    mark: [
      '/overture/reporter/',
      '/pages/reporter/'
    ]
  },
  property: {
    name: navI18n.t('primaryNavs.property'),
    link: '/pages/overview.html?from=property',
    mark: [
      '/overture/tool/store/',
      '/overture/tool/word_manage/',
      '/overture/tool/dpa/behaviour/',
      '/conversion/',
      '/overture/tool/interest_tags/',
      '/pages/toolbox/products.html',
      '/pages/toolbox/products/create.html',
      '/pages/toolbox/products/detail.html',
      '/pages/toolbox/products/auth.html',
      '/pages/toolbox/products/create-video.html',
      '/pages/toolbox/self-define-packet/create.html',
      '/pages/toolbox/self_define_packet.html',
      '/pages/toolbox/material_library.html',
      '/pages/toolbox/audience_package.html',
      '/pages/toolbox/audience_package/create.html',
      '/advertiser_package/index/',
      '/pages/toolbox/playable_material/index.html',
      '/pages/toolbox/playable_material/create.html',
    ]
  },
  finance: {
    name: navI18n.t('primaryNavs.finance'),
    link: '/overture/cash/flow/#123',
    mark: [
      '/overture/cash/',
      '/overture/smb_direct/refund/',
      '/overture/self_refund/',
      '/self_service/api/v1/pages/lite/refund.html',
      '/overture/discount/activity/',
      '/overture/discount/coupon/'
    ]
  },
  tool: {
    name: navI18n.t('primaryNavs.tool'),
    link: '/pages/overview.html?from=tool',
    mark: [
      '/overture/tool/',
      '/pages/toolbox/automate_rules.html',
      '/pages/toolbox/daren_cooperate/index.html',
      '/adlab/diagnosis',
      '/overture/account/',
      '/pages/account/security.html'
    ]
  },
  school: {
    name: navI18n.t('primaryNavs.school'),
    link: '/school/?visitor_source=ad_web&location=header1',
    mark: ['/school/'],
    hasBadge: true,
    _blank: true
  }
}

const secondaryNavs = {
  promotion_content: {
    name: navI18n.t('secondaryNavs.promotion_content'),
    svg: icon_promotion_content
  },
  directed_data: {
    name: navI18n.t('secondaryNavs.directed_data'),
    svg: icon_directed_data
  },
  creative_material: {
    name: navI18n.t('secondaryNavs.creative_material'),
    svg: icon_creative_material
  },
  convert: {
    name: navI18n.t('secondaryNavs.convert'),
    svg: icon_convert
  },
  account_tool: {
    name: navI18n.t('secondaryNavs.account_tool'),
    svg: icon_account_tool
  },
  optimization_tool: {
    name: navI18n.t('secondaryNavs.optimization_tool'),
    svg: icon_optimization_tool
  },
  design_tool: {
    name: navI18n.t('secondaryNavs.design_tool'),
    svg: icon_design_tool
  },
  clue_management_tool: {
    name: navI18n.t('secondaryNavs.clue_management_tool'),
    svg: icon_clue_management_tool
  },
  platform_tool: {
    name: navI18n.t('secondaryNavs.platform_tool'),
    svg: icon_platform_tool
  }
}

const thirdLevelNavs = {
  /* 推广内容 start */
  external: {
    name: navI18n.t('thirdLevelNavs.external'),
    link: 'https://h5.oceanengine.com/marketing/templates',
    hint: '创建、共享落地页，方便在广告创编中使用',
    hidden: true
  },
  DPA_product: {
    name: navI18n.t('thirdLevelNavs.DPA_product'),
    link: '/pages/toolbox/products.html',
    hint: '适用于商品目录推广，管理DPA广告投放的商品库内容',
    hidden: true
  },
  store: {
    name: navI18n.t('thirdLevelNavs.store'),
    link: '/overture/tool/store/index/list/',
    hint: '适用于门店推广，管理门店信息、门店活动'
  },
  advertiser_package: {
    name: navI18n.t('thirdLevelNavs.advertiser_package'),
    link: '/advertiser_package/index/',
    hint: '使用极速下载服务管理安卓APP渠道包'
  },
  /* 推广内容 end */

  /* 定向数据 start */
  audience_package: {
    name: navI18n.t('thirdLevelNavs.audience_package'),
    link: '/pages/toolbox/audience_package.html',
    hint: '将常用定向条件设置为模板，关联计划后应用至广告投放'
  },
  dmp: {
    name: navI18n.t('thirdLevelNavs.dmp'),
    link: 'https://yuntu.oceanengine.com/dmp/audience/list',
    hint: '使用DMP工具扩展定向标签，圈选自定义人群',
    _blank: true,
    out: true
  },
  interest_tags: {
    name: navI18n.t('thirdLevelNavs.interest_tags'),
    link: '/overture/tool/interest_tags/',
    hint: '配置常用的关键词，方便在兴趣定向中使用'
  },
  flow_packet: {
    name: navI18n.t('thirdLevelNavs.flow_packet'),
    link: '/pages/toolbox/self_define_packet.html',
    hint: '适用于穿山甲广告，管理流量位以实现精准投放',
    hidden: true
  },
  /* 定向数据 end */

  /* 创意素材 start */
  basic_creative: {
    name: navI18n.t('thirdLevelNavs.basic_creative'),
    link: '/pages/toolbox/material_library.html#/video',
    hint: '管理广告创意中所需要的基础素材，包括图片库、视频库、试玩素材等'
  },
  additional_creative: {
    name: navI18n.t('thirdLevelNavs.additional_creative'),
    link: 'https://clue.oceanengine.com',
    _blank: true,
    hint: '适用于销售线索收集和门店推广，通过线索通管理附加创意转化组件',
    out: true
  },
  /* 创意素材 end */

  /* 转化 start */
  convert_stat: {
    name: navI18n.t('thirdLevelNavs.convert_stat'),
    link: '/conversion/',
    hint: '根据推广目的和转化目标选择相应的转化跟踪工具，监测广告转化事件',
    hidden: true
  },
  /* 转化 end */

  /* 账户工具 start */
  opt_log: {
    name: navI18n.t('thirdLevelNavs.opt_log'),
    link: '/overture/tool/opt_log/',
    hint: '查看账户内历史操作记录',
    hidden: true
  },
  homepage_related: {
    name: navI18n.t('thirdLevelNavs.homepage_related'),
    link: '/overture/account/ies_account_binding/?app_id=1112',
    hint: '关联关山、抖音主页，创建信息流原生广告计划'
  },
  account_security: {
    name: navI18n.t('thirdLevelNavs.account_security'),
    link: '/overture/account/info/',
    hint: '查看和设置账户的名称、头像、资质等信息'
  },
  msg_center: {
    name: navI18n.t('thirdLevelNavs.msg_center'),
    link: '/overture/account/notification/',
    hint: '查看审核、投放、财务、产品更新等各类公告通知'
  },
  /* 账户工具 end */

  /* 优化工具 start */
  automate_rules: {
    name: navI18n.t('thirdLevelNavs.automate_rules'),
    link: '/pages/toolbox/automate_rules.html#/manage',
    hint: '设置自动规则自动监控和调整广告计划，高效管理广告'
  },
  diagnosis: {
    name: navI18n.t('thirdLevelNavs.diagnosis'),
    link: '/adlab/diagnosis/account',
    hint: '使用账户及计划诊断工具，定位投放问题、优化投放效果',
    hidden: true
  },
  tool_detector: {
    name: navI18n.t('thirdLevelNavs.tool_detector'),
    link: '/overture/tool/detector/create',
    hint: '检测落地页在不同网络环境中的可访问性，并根据建议优化',
    hidden: true
  },
  bid_simulator: {
    name: navI18n.t('thirdLevelNavs.bid_simulator'),
    link: '/overture/tool/bid_simulator/',
    hint: '模拟不同出价、预算情况下的转化量，调整出价和预算',
    hidden: true
  },
  /* 优化工具 end */

  /* 设计工具 start */
  creative_center: {
    name: navI18n.t('thirdLevelNavs.creative_center'),
    link: 'https://cc.oceanengine.com',
    hint: '创意制作工具，发现灵感、制作广告创意',
    _blank: true,
    out: true
  },
  stock_dfic: {
    name: navI18n.t('thirdLevelNavs.stock_dfic'),
    link: 'https://stock.dfic.cn/?source=ad_tool',
    hint: '正版图库，使用免费正版图片制作广告创意',
    _blank: true,
    out: true
  },
  market_v_home: {
    name: navI18n.t('thirdLevelNavs.market_v_home'),
    link: 'https://market.oceanengine.com/v/home',
    hint: '寻找头条、抖音达人制作广告内容',
    _blank: true,
    out: true
  },
  star: {
    name: navI18n.t('thirdLevelNavs.star'),
    link: 'https://star.toutiao.com/landing-page/adplatform',
    hint: '寻找头条、抖音达人进行深度内容营销合作',
    _blank: true,
    out: true
  },
  site: {
    name: navI18n.t('thirdLevelNavs.site'),
    link: 'https://h5.oceanengine.com/marketing/home',
    hint: '落地页制作工具，智能创建落地页用于投放',
    _blank: true,
    out: true
  },
  daren_cooperate: {
    name: navI18n.t('thirdLevelNavs.daren_cooperate'),
    link: '/pages/toolbox/daren_cooperate/index.html',
    hint: '获取达人授权，原生推广达人视频'
  },
  /* 设计工具 end */

  /* 线索管理工具 start */
  comment: {
    name: navI18n.t('thirdLevelNavs.comment'),
    link: '/overture/tool/comment/index/content/',
    hint: '评论管理工具，处理客户观看广告后的评论内容'
  },
  im_xiao6: {
    name: navI18n.t('thirdLevelNavs.im_xiao6'),
    link: 'https://im.oceanengine.com/',
    _blank: true,
    hint: '免费的IM客服工具，回复咨询与客户高效沟通',
    out: true
  },
  crm: {
    name: navI18n.t('thirdLevelNavs.crm'),
    link: 'https://feiyu.oceanengine.com/feiyu/sales',
    _blank: true,
    hint: '免费的客户管理工具，查看转化数据、一站式管理线索',
    out: true
  },
  clue: {
    name: navI18n.t('thirdLevelNavs.clue'),
    link: 'https://clue.oceanengine.com',
    _blank: true,
    hint: '营销组件设置工具，设置转化组件以回收线索',
    out: true
  },
  /* 线索管理工具 end */

  /* 平台工具 start */
  creative_app: {
    name: navI18n.t('thirdLevelNavs.creative_app'),
    link: '/school/help?category_id=6452154464005521422',
    _blank: true,
    hint: '移动端广告投放管理平台，使用APP随时随地管理广告',
    out: true
  },
  open_api: {
    name: navI18n.t('thirdLevelNavs.open_api'),
    link: '/openapi/index.html',
    _blank: true,
    hint: '接入Marketing API 实现全方位自动化营销管理',
    out: true
  },
  web_store: {
    name: navI18n.t('thirdLevelNavs.web_store'),
    link: '/webstore/',
    _blank: true,
    hint: '前往体验小工具，提升广告投放效果',
    out: true
  }
  /* 平台工具 end */
}

const sliderPrimaryNavs = {
  /* DPA商品库 start */
  product: {
    name: navI18n.t('sliderPrimaryNavs.product'),
    link: '/pages/toolbox/products.html',
    svg: slider_icon_product,
    svgLight: slider_icon_product_light
  },
  behaviour: {
    name: navI18n.t('sliderPrimaryNavs.behaviour'),
    link: '/overture/tool/dpa/behaviour/',
    svg: slider_icon_behaviour,
    svgLight: slider_icon_behaviour_light
  },
  /* DPA商品库 end */

  /* 基础创意 start */
  material_library: {
    name: navI18n.t('sliderPrimaryNavs.material_library'),
    link: '/pages/toolbox/material_library.html#/video',
    svg: slider_icon_material_library,
    svgLight: slider_icon_material_library_light
  },
  material_library_pic: {
    name: navI18n.t('sliderPrimaryNavs.material_library_pic'),
    link: '/pages/toolbox/material_library.html#/picture',
    svg: slider_icon_material_library_pic,
    svgLight: slider_icon_material_library_pic_light
  },
  word_manage: {
    name: navI18n.t('sliderPrimaryNavs.word_manage'),
    link: '/overture/tool/word_manage/',
    svg: slider_icon_word_manage,
    svgLight: slider_icon_word_manage_light
  },
  playable: {
    name: navI18n.t('sliderPrimaryNavs.playable'),
    link: '/pages/toolbox/playable_material/index.html',
    svg: slider_icon_playable,
    svgLight: slider_icon_playable_light,
    hidden: true
  },
  /* 基础创意 end */

  /* 主页关联 start */
  homepage_related_hotsoon: {
    name: navI18n.t('sliderPrimaryNavs.homepage_related_hotsoon'),
    link: '/overture/account/ies_account_binding/?app_id=1112',
    svg: slider_icon_homepage_related_hotsoon,
    svgLight: slider_icon_homepage_related_hotsoon_light
  },
  homepage_related_awewe: {
    name: navI18n.t('sliderPrimaryNavs.homepage_related_awewe'),
    link: '/overture/account/ies_account_binding/?app_id=1128',
    svg: slider_icon_homepage_related_awewe,
    svgLight: slider_icon_homepage_related_awewe_light
  },
  /* 主页关联 end */

  user: {
    name: navI18n.t('sliderPrimaryNavs.user'),
    svg: slider_icon_user,
    svgLight: slider_icon_user_light
  },
  account: {
    name: navI18n.t('sliderPrimaryNavs.account'),
    svg: slider_icon_account,
    svgLight: slider_icon_account_light
  },

  /* 消息中心 start */
  msg_list: {
    name: navI18n.t('sliderPrimaryNavs.msg_list'),
    link: '/overture/account/notification/',
    svg: slider_icon_msg_list,
    svgLight: slider_icon_msg_list_light
  },
  msg_subscription: {
    name: navI18n.t('sliderPrimaryNavs.msg_subscription'),
    link: '/overture/account/subscription/',
    svg: slider_icon_msg_subscription,
    svgLight: slider_icon_msg_subscription_light
  },
  /* 消息中心 end */

  /* 诊断 start */
  diagnosis_account: {
    name: navI18n.t('sliderPrimaryNavs.diagnosis_account'),
    link: '/adlab/diagnosis/account',
    svg: slider_icon_diagnosis_account,
    svgLight: slider_icon_diagnosis_account_light
  },
  diagnosis_ad: {
    name: navI18n.t('sliderPrimaryNavs.diagnosis_ad'),
    link: '/adlab/diagnosis',
    svg: slider_icon_diagnosis_ad,
    svgLight: slider_icon_diagnosis_ad_light
  },
  /* 诊断 end */

  fund_manage: {
    name: navI18n.t('sliderPrimaryNavs.fund_manage'),
    svg: slider_icon_fund_manage,
    svgLight: slider_icon_fund_manage_light
  },
  // GLOBAL_VAR_API.show_discount_info
  activity_coupon: {
    name: navI18n.t('sliderPrimaryNavs.activity_coupon'),
    svg: slider_icon_activity_coupon,
    svgLight: slider_icon_activity_coupon_light,
    hidden: true
  }
}

const sliderSecondaryNavs = {
  account_security: {
    name: navI18n.t('sliderSecondaryNavs.account_security'),
    link: '/pages/account/security.html'
  },
  // 待增加
  user_manage: {
    name: navI18n.t('sliderSecondaryNavs.user_manage'),
    link: '#####user manage#####',
    hidden: true
  },

  account_info: {
    name: navI18n.t('sliderSecondaryNavs.account_info'),
    link: '/overture/account/info/'
  },
  account_auth: {
    name: navI18n.t('sliderSecondaryNavs.account_auth'),
    link: '/overture/account/auth/',
    hidden: true
  },
  account_receipt: {
    name: navI18n.t('sliderSecondaryNavs.account_receipt'),
    link: '/overture/account/receipt/',
    hidden: true
  },
  account_majordomo: {
    name: navI18n.t('sliderSecondaryNavs.account_majordomo'),
    link: '/overture/account/majordomo/'
  },
  account_manage: {
    name: navI18n.t('sliderSecondaryNavs.account_manage'),
    link: '#####account manage#####',
    hidden: true
  },

  cash_flow: {
    name: navI18n.t('sliderSecondaryNavs.cash_flow'),
    link: '/overture/cash/flow/'
  },
  // JSON.parse(GLOBAL_VAR_API.showRecharge)
  // 是否可以在线充值
  recharge: {
    name: navI18n.t('sliderSecondaryNavs.recharge'),
    link: '/overture/cash/recharge/',
    hidden: true,
    log: {
      type: 'recharge-from',
      title: '财务管理-在线充值',
      delay: true
    }
  },
  // JSON.parse(GLOBAL_VAR_API.showSmbDirectRefund) || GLOBAL_VAR_API.show_self_refund
  // 是否可以在线退款
  refund: {
    name: navI18n.t('sliderSecondaryNavs.refund'),
    link: '/overture/smb_direct/refund/',
    marks: ['/overture/smb_direct/refund/', '/overture/self_refund/get_cg_self_refund_page/'],
    hidden: true,
    log: {
      type: 'refund-from',
      title: '财务管理-在线退款',
      delay: true
    }
  },
  // GLOBAL_VAR_API.show_self_receipt
  // 自助开票入口权限
  receipt: {
    name: navI18n.t('sliderSecondaryNavs.receipt'),
    mark: '/cash/receipt/',
    link: '/overture/cash/receipt/',
    hidden: true,
    log: {
      type: 'receipt-from',
      title: '财务管理-自助开票',
      delay: true
    }
  },

  discount_activity: {
    name: navI18n.t('sliderSecondaryNavs.discount_activity'),
    link: '/overture/discount/activity/',
  },
  discount_coupon: {
    name: navI18n.t('sliderSecondaryNavs.discount_coupon'),
    link: '/overture/discount/coupon/',
  }
}

export {
  icon_logo,
  icon_user_info_triangle,
  icon_i18n_triangle,
  primaryNavs,
  secondaryNavs,
  thirdLevelNavs,
  sliderPrimaryNavs,
  sliderSecondaryNavs
}