export default {
  primaryNavs: {
    home: '概要',
    extension: '広告',
    reports: 'レポート',
    property: 'アセット',
    finance: 'ファイナンス',
    tool: 'ツールボックス',
    school: 'ヘルプセンター'
  },
  secondaryNavs: {
    promotion_content: 'プロモーション内容',
    directed_data: '配信対象',
    creative_material: '素材',
    convert: 'コンバージョン',
    account_tool: 'アカウントツール',
    optimization_tool: '最適化ツール',
    design_tool: 'ディザインツール',
    clue_management_tool: 'リード管理ツール',
    platform_tool: 'プラットフォームツール'
  },
  thirdLevelNavs: {
    external: 'ランディングページ',
    DPA_product: 'DPA商品カタログ',
    store: 'ストア',
    advertiser_package: 'アプリ',
    audience_package: 'ターゲッティングパック',
    dmp: 'カスタムオーディエンスパック',
    interest_tags: '関心ワード語彙パック',
    flow_packet: 'Pangleプレースメント',
    basic_creative: 'クリエィティブ',
    additional_creative: '追加広告',
    convert_stat: 'コンバージョントラッキング',
    opt_log: 'ログ',
    homepage_related: 'アカウントバインディング',
    account_security: 'アカウント情報と設定',
    msg_center: 'お知らせセンター',
    automate_rules: '自動オプティマイズ',
    diagnosis: '診断',
    tool_detector: 'サイト品質分析',
    bid_simulator: '入札シミュレーター',
    creative_center: 'クリエィティブハブ',
    stock_dfic: 'Tuchong素材プール',
    market_v_home: '素材ツール',
    star: '星図プラットフォーム',
    site: 'サイト作成ツール',
    daren_cooperate: 'KOL認定ビデオ',
    comment: 'コメント管理',
    im_xiao6: 'Xiao6カスタマーサービス',
    crm: 'Flying Fish CRM',
    clue: 'Qingniaoリード',
    creative_app: 'Ocean Engine APP',
    open_api: 'Marketing API',
    web_store: 'Marketing API Store'
  },
  sliderPrimaryNavs: {
    product: 'カタログ管理',
    behaviour: 'DPAオーディエンス',
    material_library: '動画素材庫',
    material_library_pic: '静止画像素材庫',
    word_manage: 'ダイナミックキーワド語彙パック',
    playable: 'プレイアブル素材',
    homepage_related_hotsoon: 'Huoshanアカウントバインディング',
    homepage_related_awewe: 'Douyinアカウントバインディング',
    user: 'ユーザー',
    account: 'アカウント',
    msg_list: 'お知らせ一覧',
    msg_subscription: '通知設定',
    diagnosis_account: 'アカウント診断',
    diagnosis_ad: '広告セット診断',
    fund_manage: '取引履歴',
    activity_coupon: '活动与赠款'
  },
  sliderSecondaryNavs: {
    account_security: 'アカウントセキュリティ',
    user_manage: 'アカウント情報',
    account_info: 'アカウント一覧',
    account_auth: '認証管理',
    account_receipt: '請求書情報',
    account_majordomo: 'アカウントマネジャー',
    account_manage: '広告アカウント管理',
    cash_flow: '請求書',
    recharge: 'オンラインチャージ',
    refund: 'オンライン払い戻し',
    receipt: '自動インヴォイス',
    discount_activity: 'キャンペーン一覧',
    discount_coupon: 'クーポン金額'
  },
  common: {
    subtitle: '広告プラットフォーム'
  }
}