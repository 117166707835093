export default {
  toArray (obj) {
    return Array.prototype.slice.call(obj)
  },
  getAttr (node, key, defaultValue) {
    if (node && node.attributes[key]) {
      return node.attributes[key].value.trim()
    }
    return defaultValue || ''
  },
  setAttr(node, key, value){
    if (node) {
      node.setAttribute(key, value)
    }
  },
  merge (desObj, srcObj) {
    for (let item in srcObj) {
      if (typeof srcObj[item] !== 'undefined') {
        if (this.objType(srcObj[item]) === 'Object') {
          if (!desObj[item])
            desObj[item] = {}
          this.merge(desObj[item], srcObj[item])
        } else if (this.objType(srcObj[item]) === 'Array' && this.objType(desObj[item]) === 'Array') {
          desObj[item] = [...new Set(desObj[item].concat(srcObj[item]))]
        } else {
          desObj[item] = srcObj[item]
        }
      }
    }
  },
  hasClass(el, cls) {
    if (!el || !cls) return false
    if (cls.indexOf(' ') !== -1) {
      throw new Error('className should not contain space.')
    }
    if (el.classList) {
      return el.classList.contains(cls)
    } else {
      return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1
    }
  },
  addClass (el, cls) {
    if (!el) return
    let curClass = el.className
    const classes = (cls || '').split(' ')

    for (let i = 0, j = classes.length; i < j; i++) {
      const clsName = classes[i]
      if (!clsName) continue

      if (el.classList) {
        el.classList.add(clsName)
      } else {
        if (!hasClass(el, clsName)) {
          curClass += ' ' + clsName
        }
      }
    }
    if (!el.classList) {
      el.className = curClass
    }
  },
  removeClass (el, cls) {
    if (!el || !cls) return
    const classes = cls.split(' ')
    let curClass = ' ' + el.className + ' '

    for (let i = 0, j = classes.length; i < j; i++) {
      const clsName = classes[i]
      if (!clsName) continue

      if (el.classList) {
        el.classList.remove(clsName)
      } else {
        if (hasClass(el, clsName)) {
          curClass = curClass.replace(' ' + clsName + ' ', ' ')
        }
      }
    }
    if (!el.classList) {
      el.className = trim(curClass)
    }
  },
  /**
   *  白名单功能判断支持函数
   *  @parama value 白名单功能值
   */
  isSupported (value) {
    let funs = []
    try {
      funs = JSON.parse(window.GLOBAL_VAR_API.funcsOpened)
    } catch (e) {
      //
    }
    return funs.indexOf(parseInt(value)) > -1
  },
  /**
   * 将JSON数据解析为JS对象，可捕获异常
   * @param {string} json JSON形式的字符串，可以不符合json规则或undefined
   * @returns {object} obj 经JSON.parse()后的结果，若无法解析，则为undefined
   */
  catchableParse (json) {
    try {
      return JSON.parse(json)
    } catch (e) {
      return
    }
  },
  objType (obj) {
    return Object.prototype.toString.call(obj).match(/^\[object (.*)\]$/)[1]
  },
  render (node, text, position) {
    position = position || 'afterbegin' // 默认插入元素内部的第一个子节点之前
    node.insertAdjacentHTML(position, text)
  },
  insertBeforeLastNav (parent, text, dom) {
    var template = document.createElement('template')
    template.innerHTML = text
    parent.insertBefore(template.content.firstChild, dom)
  },
  secHtml (str) {
    str = (str || '').toString()
    let character = {
      '<': '&lt;',
      '>': '&gt;',
      '&': '&amp;',
      '"': '&quot;',
      '\'': '&#039;'
    }
    return str.replace(/[<>&"']/g, function(c) {
      return character[c]
    })
  },
  actionListener (targets, event, callback) {
    function bindEvent(target) {
      if (target.addEventListener) {
        target.addEventListener(event, callback, false)
      } else {
        target.attachEvent(event, callback) //IE
      }
    }
    if (!targets)
      return
    if (targets.length) {
      Array.prototype.slice.call(targets).forEach(target => this.actionListener(target, event, callback))
    } else {
      this.objType(event) === 'Array'
        ? event.forEach(evt => this.actionListener(targets, evt, callback))
        : bindEvent(targets)
    }
  },
  ajax (type, options, async = true) {
    let xmlHttp = this.createHttp()
    if (!xmlHttp) {
      return
    }
    xmlHttp.open(type, options.url, async)
    options.headers && Object.keys(options.headers).forEach(headerName => {
      xmlHttp.setRequestHeader(headerName, options.headers[headerName])
    })
    xmlHttp.withCredentials = true
    if (options.headers && xmlHttp.overrideMimeType && options.headers['Content-Type']) {
      xmlHttp.overrideMimeType(options.headers['Content-Type'])
    }
    xmlHttp.onreadystatechange = (event) => {
      if (event.target.readyState === 4) {
        let resp = {}
        if (options.parse) {
          try {
            resp = JSON.parse(event.target.responseText)
          } catch (e) {
            console.log(e)
          }
        }
        if (event.target.status === 200) {
          options.success && options.success(options.parse ? resp : event.target.responseText)
        } else {
          options.error && options.error(options.parse ? resp : event.target.responseText)
        }
      }
    }
    xmlHttp.send(options.data || null)
  },
  createHttp () {
    let xmlhttp = null
    try {
      xmlhttp = new XMLHttpRequest()
    } catch (e) {
      try {
        xmlhttp = ActiveXobject("Msxml12.XMLHTTP")
      } catch (e1) {
        try {
          xmlhttp = ActiveXobject("Microsoft.XMLHTTP")
        } catch (e2) {
          console.log(e2)
        }
      }
    }
    return xmlhttp
  },
  queryToJson (url) {
    url = url || window.location.href
    let params = url.slice(url.indexOf('?') + 1)
    let obj = {}
    params.split('&').forEach(item => {
      let row = item.split('=')
      if (row.length === 2) {
        obj[row[0]] = decodeURIComponent(row[1])
      }
    })
    return obj
  },
  jsonToQuery (obj, prefix) {
    return (typeof (prefix) === 'undefined' ? '?' : prefix) + Object.keys(obj)
      .filter(item => (typeof (obj[item]) !== 'undefined') && (obj[item] !== null))
      .map(item => {
        return [item, encodeURIComponent(obj[item])].join('=')
      }).join('&')
  },
  handleI18nLink (curLink) {
    // const locale = this.queryToJson().locale || ''
    // const whiteI18nList = ['en', 'ja']
    // if (whiteI18nList.includes(locale)) {
    //   const paramObj = this.queryToJson(curLink)
    //   paramObj.locale = locale
    //   curLink = (curLink.indexOf('?') > -1 ? curLink.slice(0, curLink.indexOf('?')) : curLink) + this.jsonToQuery(paramObj)
    // }
    return curLink
  },
  getCookieItem (sKey) {
    return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null
  }
}