import tool from '../tool'

const renderConfig = (context, position) => {
  let classPrefix = position === 'slider' ? {
    container: `.${context.CSS_NAMESPACE}-slider-container`,
    'title-container': `.${context.CSS_NAMESPACE}-slider-title-container`,
    'list-container': `.${context.CSS_NAMESPACE}-slider-list-container`
  } : {
    container: `.${context.CSS_NAMESPACE}-header-container`,
    'logo-container': `.${context.CSS_NAMESPACE}-header-logo-container`,
    'text-container': `.${context.CSS_NAMESPACE}-header-text-container`,
    'nav-container': `.${context.CSS_NAMESPACE}-header-nav-container`,
    'icon-container': `.${context.CSS_NAMESPACE}-header-icon-container`,
    'i18n-container': `.${context.CSS_NAMESPACE}-header-i18n-container`,
    'avatar-container': `.${context.CSS_NAMESPACE}-header-avatar-container`,
  }
  return Object.keys(classPrefix)
    .map(item => renderConfigStyle(context, classPrefix, item, position))
    .join('\n')
}

const renderConfigStyle = (context, classPrefix, type, position) => {
  const cssPrefix = position === 'slider' ? 'slider_css' : 'header_css'
  const curConfig = position === 'slider' ? context.sliderConfig : context.config
  return `
    ${Object.keys(curConfig[cssPrefix][type]).map(item => {
      let className = item === 'style'
        ? classPrefix[type]
        : classPrefix[type] + (item === ':after' ? item : ' ' + item)
      return `
        ${className} {
          ${Object.keys(curConfig[cssPrefix][type][item]).map(cssName => {
            return tool.secHtml(`
              ${cssName}: ${curConfig[cssPrefix][type][item][cssName]};
            `.trim())
          }).join('\n')}
        }
      `.trim()
    }).join('\n')}
  `.trim()
}

const renderCommonStyle = (context) => {
  return `
    .${context.CSS_NAMESPACE}-header-container * {
      margin: 0px;
      padding: 0px;
      box-sizing: border-box;
      font-family: "PingFangSC-Regular", "Microsoft YaHei";
    }
    .${context.CSS_NAMESPACE}-header-display-none {
      display: none!important;
    }
    .${context.CSS_NAMESPACE}-header-display-block {
      display: block;
    }
    .${context.CSS_NAMESPACE}-header-forbidden-overflow {
      overflow: hidden!important;
    }
    .${context.CSS_NAMESPACE}-header-logo-container .${context.CSS_NAMESPACE}-header-logo-icon {
      display: flex;
      width: 100%;
      height: 100%;
      cursor: pointer;
      text-decoration: none!important;
    }
    .${context.CSS_NAMESPACE}-header-logo-container .${context.CSS_NAMESPACE}-header-logo-icon>svg {
      width: 100%;
      height: 100%;
    }
    .${context.CSS_NAMESPACE}-header-login-verticle-line {
      position: absolute;
      right: -16px;
      top: 20px;
      height: 24px;
      width: 0;
      border-right: 1px solid rgba(255,255,255,0.3);
    }
    .${context.CSS_NAMESPACE}-header-title {
      user-select: none;
    }
    .${context.CSS_NAMESPACE}-header-nav {
      display: inline-block;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      position: relative;
    }
    .${context.CSS_NAMESPACE}-header-nav-dropdown {
      display: none;
      position: absolute;
      top: 64px;
      left: 0;
      right: 0;
      overflow: hidden;
      background: #fff;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
      z-index: 99999;
      flex-direction: column;
    }
    .${context.CSS_NAMESPACE}-header-nav-dropdown > a {
      text-decoration: none;
    }
    .${context.CSS_NAMESPACE}-header-nav-dropdown.${context.CSS_NAMESPACE}-header-nav-dropdown-show {
      display: flex;
    }
    .${context.CSS_NAMESPACE}-header-nav[data-nav-active=true]>a,
    .${context.CSS_NAMESPACE}-header-nav:hover>a,
    .${context.CSS_NAMESPACE}-header-nav.${context.CSS_NAMESPACE}-header-nav-dropdown-hover>a {
      color: #FFFFFF;
    }
    .${context.CSS_NAMESPACE}-header-nav[data-nav-active=true]>a:after{
      content: '';
      display: inline-block;
      width: 24px;
      height: 4px;
      background: #2F88FF;
      border-radius: 2px;
      position: absolute;
      bottom: 13px;
      left: 50%;
      transform: translateX(-50%);
    }
    .${context.CSS_NAMESPACE}-header-nav-name {
      cursor: pointer;
      color: #999;
      user-select: none;
      text-decoration: none!important;
      display: inline-block;
      padding: 22px 20px;
    }
    .${context.CSS_NAMESPACE}-header-nav-name:hover {
      color: #FFFFFF;
    }
    .${context.CSS_NAMESPACE}-header-nav-name:focus {
      color: rgba(255,255,255,0.65);
    }
    .${context.CSS_NAMESPACE}-header-nav-name[data-nav-a-has-badge=true]+.${context.CSS_NAMESPACE}-header-nav-a-badge {
      position: absolute;
      right: 12px;
      top: 22px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #F45858;
    }
    .${context.CSS_NAMESPACE}-header-panel {
      display: flex;
      width: 100%;
      min-height: 196px;
      max-height: 614px;
      flex: 1;
    }
    .${context.CSS_NAMESPACE}-header-link-tips {
      height: 34px;
      background: #F8F9FA;
      line-height: 34px;
      text-align: left;
      font-size: 0;
    }
    .${context.CSS_NAMESPACE}-header-link-tips > div[data-icon-link-tip] {
      width: 14px;
      height: 14px;
      display: inline-block;
      line-height: 0;
      vertical-align: -3px;
      margin-left: 32px;
    }
    .${context.CSS_NAMESPACE}-header-link-tips > span[data-header-link-tips] {
      font-size: 12px;
      color: #999;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      margin-left: 4px;
    }
    .${context.CSS_NAMESPACE}-header-panel-left {
      flex: 1;
      display: flex;
      padding: 24px 32px;
      overflow-y: auto;
      justify-content: space-between;
    }
    ${
      [...new Array(6)].map((key, index) => `
        .${context.CSS_NAMESPACE}-header-list-${index+1} {
          text-align: left;
          width: ${100/(index+1)}%;
        }
      `).join('\n')
    }
    .${context.CSS_NAMESPACE}-header-list-title {
      font-size: 0;
      height: 22px;
      line-height: 22px;
      cursor: default;
    }
    .${context.CSS_NAMESPACE}-header-list-title-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: -3px;
    }
    .${context.CSS_NAMESPACE}-header-list-title > span {
      font-family: PingFang SC;
      font-size: 14px;
      color: #333;
      font-weight: 600;
      margin-left: 8px;
    }
    .${context.CSS_NAMESPACE}-header-list-ul {
      padding: 0;
      list-style: none;
    }
    .${context.CSS_NAMESPACE}-header-list-ul>li {
      display: block!important;
      margin-top: 16px;
      height: 22px;
      line-height: 22px;
    }
    .${context.CSS_NAMESPACE}-header-list-ul>li>a {
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      padding-left: 24px;
      width: 100%;
      height: 100%;
      color: #333;
      text-decoration: none!important;
      display: inline-block;
      position: relative;
      background-color: transparent;
    }
    .${context.CSS_NAMESPACE}-header-list-ul>li:hover>a {
      color: #2F88FF;
    }
    .${context.CSS_NAMESPACE}-header-list-icon-out-link>svg,
    .${context.CSS_NAMESPACE}-header-list-icon-out-link-light>svg {
      width: 14px;
      height: 14px;
      vertical-align: -2px;
    }
    .${context.CSS_NAMESPACE}-header-list-ul>li>a>.${context.CSS_NAMESPACE}-header-list-icon-out-link-light {
      display: none;
    }
    .${context.CSS_NAMESPACE}-header-list-ul>li:hover>a>.${context.CSS_NAMESPACE}-header-list-icon-out-link {
      display: none;
    }
    .${context.CSS_NAMESPACE}-header-list-ul>li:hover>a>.${context.CSS_NAMESPACE}-header-list-icon-out-link-light {
      display: inline;
    }
    .${context.CSS_NAMESPACE}-header-panel-right {
      width: 350px;
    }
    .${context.CSS_NAMESPACE}-header-panel-right-container {
      margin: 24px 32px 24px 0;
      box-sizing: content-box;
      width: 318px;
      height: 159px;
      position: relative;
      overflow: hidden;
    }
    .${context.CSS_NAMESPACE}-header-panel-right-container > a {
      text-decoration: none;
      font-size: 0;
    }
    .${context.CSS_NAMESPACE}-header-panel-right-container-list {
      position: absolute;
      z-index: 1;
      width: 9999px;
      height: 100%;
    }
    .${context.CSS_NAMESPACE}-header-panel-right-container-list > img {
      float: left;
      width: 318px;
    }
    .${context.CSS_NAMESPACE}-header-icon-container.${context.CSS_NAMESPACE}-header-display-none:after {
      display: none;
    }
    .${context.CSS_NAMESPACE}-header-icon-container:after {
      content: '';
    }
    .${context.CSS_NAMESPACE}-header-icon {
      width: 20px;
      padding: 0 12px;
      height: 100%;
      box-sizing: content-box;
      display: inline-block;
      position: relative;
      cursor: pointer;
    }
    .${context.CSS_NAMESPACE}-header-icon .${context.CSS_NAMESPACE}-header-svg {
      width: 100%;
      height: 100%;
      display: none;
    }
    .${context.CSS_NAMESPACE}-header-icon .${context.CSS_NAMESPACE}-header-svg>svg {
      width: 100%;
      height: 100%;
    }
    .${context.CSS_NAMESPACE}-header-icon[data-icon-active=false] .${context.CSS_NAMESPACE}-header-svg[data-svg-active=false] {
      display: block;
    }
    .${context.CSS_NAMESPACE}-header-icon[data-icon-active=true] .${context.CSS_NAMESPACE}-header-svg[data-svg-active=true] {
      display: block;
    }
    .${context.CSS_NAMESPACE}-header-icon:hover .${context.CSS_NAMESPACE}-header-svg[data-svg-active=false] {
      display: none;
    }
    .${context.CSS_NAMESPACE}-header-icon:hover .${context.CSS_NAMESPACE}-header-svg[data-svg-active=true] {
      display: block;
    }
    .${context.CSS_NAMESPACE}-header-i18n-container:after {
      content: '';
    }
    .${context.CSS_NAMESPACE}-header-i18n-container div[data-i18n-triangle-svg] {
      width: 16px;
      height: 16px;
      transition: all .3s;
      margin-left: 8px;
    }
    .${context.CSS_NAMESPACE}-header-i18n-container span[data-current-locale] {
      font-size: 14px;
      transition: all .3s;
      color: #999;
    }
    .${context.CSS_NAMESPACE}-header-i18n-container:hover div[data-i18n-triangle-svg] {
      transform: rotate(180deg);
    }
    .${context.CSS_NAMESPACE}-header-i18n-container:hover div[data-i18n-triangle-svg] svg path {
      fill: #FFF;
    }
    .${context.CSS_NAMESPACE}-header-i18n-container:hover span[data-current-locale] {
      color: #FFF;
    }
    .${context.CSS_NAMESPACE}-header-i18n-container:hover .${context.CSS_NAMESPACE}-header-i18n-dropdown {
      display: block;
    }
    .${context.CSS_NAMESPACE}-header-i18n-dropdown {
      display: none;
      position: absolute;
      max-height: 192px;
      width: 80px;
      background-color: #FFFFFF;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      left: 24px;
      top: 46px;
      z-index: 2999;
      cursor: default;
    }
    .${context.CSS_NAMESPACE}-header-i18n-dropdown > ul {
      padding: 6px 0;
      list-style: none;
    }
    .${context.CSS_NAMESPACE}-header-i18n-dropdown > ul > li {
      list-style: none;
      font-family: PingFang SC;
      font-size: 14px;
      padding: 6px 12px;
      cursor: pointer;
    }
    .${context.CSS_NAMESPACE}-header-i18n-dropdown > ul > li:hover {
      color: #2F88FF;
    }
    .${context.CSS_NAMESPACE}-header-avatar {
      width: 30px;
      height: 30px;
      margin-top: 17px;
      margin-right: 8px;
      display: inline-block;
    }
    .${context.CSS_NAMESPACE}-header-avatar>svg {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .${context.CSS_NAMESPACE}-header-user-container {
      display: inline-block;
      font-size: 14px;
      vertical-align: middle;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(255,255,255,0.75);
      padding-right: 24px;
      height: 100%;
      position: relative;
      vertical-align: top;
    }
    .${context.CSS_NAMESPACE}-header-user-info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 126px;
      user-select: text;
    }
    .${context.CSS_NAMESPACE}-header-user-info>div {
      font-size: 10px;
      height: 20px;
      line-height: 20px;
    }
    .${context.CSS_NAMESPACE}-header-user-account-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 136px;
    }
    .${context.CSS_NAMESPACE}-header-user-account-id {
    }
    .${context.CSS_NAMESPACE}-header-user-info-triangle {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 0;
      top: 20px;
      transition: all 0.3s;
    }
    .${context.CSS_NAMESPACE}-header-avatar-container:hover .${context.CSS_NAMESPACE}-header-user-info-triangle {
      transform: rotate(180deg);
    }
    .${context.CSS_NAMESPACE}-header-modal {
      display: none;
    }
    .${context.CSS_NAMESPACE}-header-avatar-container[data-icon-active=true] .${context.CSS_NAMESPACE}-header-modal,
    .${context.CSS_NAMESPACE}-header-avatar-container:hover .${context.CSS_NAMESPACE}-header-modal {
      display: block;
      position: absolute;
      right: -3px;
      top: 64px;
      z-index: 1001;
      width: 151px;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
    }
    .${context.CSS_NAMESPACE}-header-icon .${context.CSS_NAMESPACE}-header-modal {
      position: absolute;
      left: -74px;
      top: 64px;
      z-index: 1001;
      width: 120px;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      cursor: default;
    }
    .${context.CSS_NAMESPACE}-header-icon[data-icon-active=true] .${context.CSS_NAMESPACE}-header-modal {
      display: block;
    }
    .${context.CSS_NAMESPACE}-header-icon:hover .${context.CSS_NAMESPACE}-header-modal {
      display: block;
    }
    .${context.CSS_NAMESPACE}-header-modal:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      position: absolute;
      top: -12px;
      right: 23px;
      border-top: 6px solid transparent;
      border-bottom: 6px solid #FFFFFF;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
    .${context.CSS_NAMESPACE}-header-modal .${context.CSS_NAMESPACE}-header-tab {
      display: block;
      text-decoration: none!important;
      cursor: pointer;
      padding: 12px 20px;
      border-bottom: 1px solid #F3F4F6;
      font-size: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      line-height: 17px;
    }
    .${context.CSS_NAMESPACE}-header-modal .${context.CSS_NAMESPACE}-header-tab:hover {
      background: #FAFAFA;
    }
    .${context.CSS_NAMESPACE}-header-tab.${context.CSS_NAMESPACE}-header-not-hover:hover {
      background: #ffffff;
      cursor: default;
    }
    .${context.CSS_NAMESPACE}-header-modal .${context.CSS_NAMESPACE}-header-tab>span {
      color: #333333;
      line-height: 20px;
    }
    .${context.CSS_NAMESPACE}-header-modal .${context.CSS_NAMESPACE}-header-tab:hover>span {
      color: #2F88FF;
    }
    .${context.CSS_NAMESPACE}-header-modal .${context.CSS_NAMESPACE}-header-tab>svg {
      display: inline-block;
      width: 24px;
      height: 11px;
      vertical-align: middle;
    }
    .${context.CSS_NAMESPACE}-header-modal .${context.CSS_NAMESPACE}-header-tab .operator {
      color: rgba(153,153,153,1);
      margin-bottom: 4px;
    }
    .${context.CSS_NAMESPACE}-header-modal .${context.CSS_NAMESPACE}-header-tab .operator-name {
      color: rgba(51,51,51,1);
    }
    .${context.CSS_NAMESPACE}-header-msg-tab {
      padding: 12px 20px;
      border-bottom: 1px solid #F3F4F6;
      font-size: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      line-height: 17px;
    }
    .${context.CSS_NAMESPACE}-header-msg-tab:last-child {
      border: none;
    }
    .${context.CSS_NAMESPACE}-header-msg-tab:hover {
      background: #FAFAFA;
    }
    .${context.CSS_NAMESPACE}-header-msg-tab>a {
      text-decoration: none!important;
      color: #333333;
      line-height: 20px;
    }
    .${context.CSS_NAMESPACE}-header-msg-tab:hover>a {
      color: #2F88FF;
    }
    .${context.CSS_NAMESPACE}-header-msg-tab>span {
      height: 15px;
      line-height: 15px;
      font-size: 12px;
      color: #8b8b8b;
    }
    .${context.CSS_NAMESPACE}-header-options {
      height: 35px;
      line-height: 35px;
      border-top: 1px solid #F3F4F6;
    }
    .${context.CSS_NAMESPACE}-header-options>a {
      padding-left: 24px;
      font-size: 14px;
      color: #5d7ca5;
      text-decoration: none!important;
    }
    .${context.CSS_NAMESPACE}-header-options>span {
      font-size: 14px;
      color: #5d7ca5;
      float: right;
      margin-right: 24px;
      cursor: pointer;
    }
    .${context.CSS_NAMESPACE}-header-app-download {
      padding: 14px 0 16px;
      font-size: 0;
      line-height: 0px;
      cursor: default;
    }
    .${context.CSS_NAMESPACE}-header-person {
      width: 180px;
      padding: 8px 12px 8px 16px;
      line-height: 20px;
      font-size:12px;
      color: #fff;
      background-color:#2F88FF;
      box-shadow:0px 2px 6px 0px rgba(0,0,0,0.08);
      border-radius:4px;
    }
    .${context.CSS_NAMESPACE}-header-person .app-download-title>span{
      display: block;
      float: right;
      cursor: pointer;
    }
    .${context.CSS_NAMESPACE}-header-person .app-download-title>span>img{
      width: 16px;
      height: 16px;
      margin-top:2px;
      display: block;
    }
    .${context.CSS_NAMESPACE}-header-app-download:hover {
    }
    .${context.CSS_NAMESPACE}-header-app-download .app-download-title {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(51,51,51,1);
      margin-bottom: 10px;
      line-height: 14px;
    }
    .${context.CSS_NAMESPACE}-header-app-download .app-download-tip {
      width: 68px;
      margin-left: 20px;
      transform: translateY(3px);
    }
    .${context.CSS_NAMESPACE}-header-app-download .app-download-wxtip {
      display: inline-block;
      font-size: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(51,51,51,1);
      margin: 0 0 10px 20px;
      line-height: 14px;
    }
    .${context.CSS_NAMESPACE}-header-app-download>img {
      width: 68px;
      height: 68px;
      margin-left: 20px;
    }
    .${context.CSS_NAMESPACE}-header-app-download.${context.CSS_NAMESPACE}-header-app-ad {
      padding-bottom: 10px;
      padding-top: 16px;
    }
    .${context.CSS_NAMESPACE}-header-app-download.${context.CSS_NAMESPACE}-header-app-ad .app-download-ad-tip {
      margin-top: 5px;
      text-align: center;
      font-size: 10px;
      height: 14px;
      line-height: 14px;
      cursor: pointer;
    }
    .${context.CSS_NAMESPACE}-header-app-download.${context.CSS_NAMESPACE}-header-app-ad .app-download-ad-tip a {
      text-decoration: none!important;
      color: #999;
    }
    .${context.CSS_NAMESPACE}-header-app-download.${context.CSS_NAMESPACE}-header-app-ad .app-download-ad-tip:hover a {
      color: #2F88FF;
    }
    .${context.CSS_NAMESPACE}-header-app-download.${context.CSS_NAMESPACE}-header-app-ad>img {
      width: 82px;
      height: 82px;
      margin: 0 19px;
    }
    .${context.CSS_NAMESPACE}-header-login-container {
      position: absolute;
      top: 14px;
      right: 0;
    }
    .${context.CSS_NAMESPACE}-header-login-btn, .${context.CSS_NAMESPACE}-header-register-btn {
      display: inline-block;
      width: 92px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #2F88FF;
      border-radius: 17px;
      color: white;
      font-size: 12px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      letter-spacing: 1px;
      cursor: pointer;
      user-select: none;
    }
    .${context.CSS_NAMESPACE}-header-register-btn {
      width: 75px;
      line-height: 32px;
      background: none;
      margin-left: 20px;
      border: 1px solid rgba(255,255,255,1);
      font-weight: 400;
      text-decoration: none;
    }
    .${context.CSS_NAMESPACE}-header-register-btn:hover, .${context.CSS_NAMESPACE}-header-register-btn:focus {
      text-decoration: none;
    }
    .${context.CSS_NAMESPACE}-header-register-btn:hover {
      color: #2F88FF;
    }

    /* 头部国际化部分 */
    html[lang=en] a.${context.CSS_NAMESPACE}-header-nav-name {
      padding: 22px 14px;
    }
    html[lang=en] .${context.CSS_NAMESPACE}-header-nav-name[data-nav-a-has-badge=true]+.${context.CSS_NAMESPACE}-header-nav-a-badge {
      right: 4px;
    }
    @media (max-width: 1330px) {
      html[lang=en] a.${context.CSS_NAMESPACE}-header-nav-name {
        padding: 22px 6px;
      }
      html[lang=en] .${context.CSS_NAMESPACE}-header-nav-name[data-nav-a-has-badge=true]+.${context.CSS_NAMESPACE}-header-nav-a-badge {
        right: -2px;
      }
    }
    @media (min-width: 1330px) and (max-width: 1390px) {
      html[lang=en] a.${context.CSS_NAMESPACE}-header-nav-name {
        padding: 22px 8px;
      }
      html[lang=en] .${context.CSS_NAMESPACE}-header-nav-name[data-nav-a-has-badge=true]+.${context.CSS_NAMESPACE}-header-nav-a-badge {
        right: 0px;
      }
    }
    @media (max-width: 1366px) {
      html[lang=ja] a.${context.CSS_NAMESPACE}-header-nav-name {
        padding: 22px 4px;
      }
      html[lang=ja] .${context.CSS_NAMESPACE}-header-nav-name[data-nav-a-has-badge=true]+.${context.CSS_NAMESPACE}-header-nav-a-badge {
        right: 0px;
      }
    }
    @media (min-width: 1366px) {
      html[lang=ja] a.${context.CSS_NAMESPACE}-header-nav-name {
        padding: 22px 10px;
      }
      html[lang=ja] .${context.CSS_NAMESPACE}-header-nav-name[data-nav-a-has-badge=true]+.${context.CSS_NAMESPACE}-header-nav-a-badge {
        right: 0px;
      }
    }
  `.trim()
}

const renderSliderCommonStyle = (context) => {
  return `
    .${context.CSS_NAMESPACE}-slider-container * {
      margin: 0px;
      padding: 0px;
      box-sizing: border-box;
      font-family: "PingFangSC-Regular", "Microsoft YaHei";
    }
    .${context.CSS_NAMESPACE}-slider-title {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #333333;
      margin-left: 8px;
    }
    .${context.CSS_NAMESPACE}-slider-single-list-ul {
      width: 100%;
      list-style: none;
    }
    .${context.CSS_NAMESPACE}-slider-list-ul {
      width: 100%;
      list-style: none;
    }
    .${context.CSS_NAMESPACE}-slider-list-ul > li {
      width: 100%;
      list-style: none;
    }
    .${context.CSS_NAMESPACE}-slider-list-li {
      width: 100%;
      padding: 13px 24px;
      cursor: pointer;
      list-style: none;
    }
    .${context.CSS_NAMESPACE}-slider-list-li > div {
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: -3px;
      line-height: 0;
    }
    .${context.CSS_NAMESPACE}-slider-list-li > div[data-svg-light=true] {
      display: none;
    }
    .${context.CSS_NAMESPACE}-slider-list-li > span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
      margin-left: 8px;
      user-select: none;
    }
    .${context.CSS_NAMESPACE}-slider-list-li[data-active-li=true] {
      background: #F0F9FF;
    }
    .${context.CSS_NAMESPACE}-slider-list-li:hover > div[data-svg-light=false],
    .${context.CSS_NAMESPACE}-slider-list-li[data-active-li=true] > div[data-svg-light=false] {
      display: none;
    }
    .${context.CSS_NAMESPACE}-slider-list-li:hover > div[data-svg-light=true],
    .${context.CSS_NAMESPACE}-slider-list-li[data-active-li=true] > div[data-svg-light=true] {
      display: inline-block;
    }
    .${context.CSS_NAMESPACE}-slider-list-li:hover > span,
    .${context.CSS_NAMESPACE}-slider-list-li[data-active-li=true] > span {
      color: #2F88FF;
    }
    .${context.CSS_NAMESPACE}-slider-multi-container {
      width: 100%;
    }
    .${context.CSS_NAMESPACE}-slider-multi-title-container {
      width: 100%;
      padding: 13px 40px 13px 24px;
      position: relative;
      cursor: pointer;
    }
    .${context.CSS_NAMESPACE}-slider-multi-title-container > div[data-slider-primary-svg-light] {
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: -3px;
      line-height: 0;
    }
    .${context.CSS_NAMESPACE}-slider-multi-title-container > div[data-slider-primary-svg-light=true] {
      display: none;
    }
    .${context.CSS_NAMESPACE}-slider-multi-title-container:hover > div[data-slider-primary-svg-light=true],
    .${context.CSS_NAMESPACE}-slider-multi-container[data-active-status=true] div[data-slider-primary-svg-light=true] {
      display: inline-block;
    }
    .${context.CSS_NAMESPACE}-slider-multi-title-container:hover > div[data-slider-primary-svg-light=false],
    .${context.CSS_NAMESPACE}-slider-multi-container[data-active-status=true] div[data-slider-primary-svg-light=false] {
      display: none;
    }
    .${context.CSS_NAMESPACE}-slider-multi-title-container > span {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      margin-left: 8px;
      user-select: none;
    }
    .${context.CSS_NAMESPACE}-slider-multi-title-container:hover > span,
    .${context.CSS_NAMESPACE}-slider-multi-container[data-active-status=true] .${context.CSS_NAMESPACE}-slider-multi-title-container > span {
      color: #2F88FF;
    }
    .${context.CSS_NAMESPACE}-slider-multi-title-container > div[data-slider-multi-triangle] {
      position: absolute;
      top: 15px;
      right: 24px;
      transition: all 0.3s;
      width: 16px;
      height: 16px;
      line-height: 0;
    }
    .${context.CSS_NAMESPACE}-slider-multi-title-container:hover > div[data-slider-multi-triangle] svg path {
      fill: #2F88FF;
    }
    .${context.CSS_NAMESPACE}-slider-list-ul {
      width: 100%;
      list-style: none;
    }
    .${context.CSS_NAMESPACE}-slider-multi-list-li {
      width: 100%;
      padding: 13px 24px 13px 48px;
      cursor: pointer;
      list-style: none;
    }
    .${context.CSS_NAMESPACE}-slider-multi-list-li > span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #333;
      user-select: none;
    }
    .${context.CSS_NAMESPACE}-slider-multi-list-li[data-active-li=true] {
      background: #F0F9FF;
    }
    .${context.CSS_NAMESPACE}-slider-multi-list-li:hover > span,
    .${context.CSS_NAMESPACE}-slider-multi-list-li[data-active-li=true] > span {
      color: #2F88FF;
    }
    .${context.CSS_NAMESPACE}-slider-multi-container[data-open-status=false] > .${context.CSS_NAMESPACE}-slider-list-ul {
      display: none;
    }
    .${context.CSS_NAMESPACE}-slider-multi-container[data-open-status=false] div[data-slider-multi-triangle] {
      transform: rotate(-180deg);
    }
  `.trim()
}

const renderExtraStyle = (context, position) => {
  const curConfig = position === 'slider' ? context.sliderConfig : context.config
  const replaceNameSpace = position === 'slider' ? `${context.CSS_NAMESPACE}-slider` : `${context.CSS_NAMESPACE}-header`
  let exterStyleText = tool.objType(curConfig.extra_style_func) === 'Function' ? curConfig.extra_style_func() : ''
  exterStyleText = exterStyleText.replace(/CSS_NAMESPACE/g, replaceNameSpace)
  return `${exterStyleText}`.trim()
}


export const renderStyleCss = (context, position) => {
  return `
    <style type="text/css" data-src-type="toutiao-navigator-sdk">
      ${position === 'slider' ? renderSliderCommonStyle(context) : renderCommonStyle(context)}
      ${renderConfig(context, position)}
      ${renderExtraStyle(context, position)}
    </style>
  `.trim()
}