import defaultLang from 'Src/i18n/lang/zh_CN.js'
import enLang from 'Src/i18n/lang/en.js'
import jaLang from 'Src/i18n/lang/ja.js'
let lang = defaultLang

export default {
  // 解析path路径到对应的value值
  t (path, options) {
    const array = path.split('.')
    let current = lang
    let value

    for (let i = 0, j = array.length; i < j; i++) {
      const property = array[i]
      value = current[property]
      if (i === j - 1) return value
      if (!value) return ''
      current = value
    }
    return ''
  },
  // 切换 语言包
  use (locale) {
    switch (locale) {
      case 'en':
        lang = enLang
        break
      case 'ja':
        lang = jaLang
        break
      default:
        lang = defaultLang
        break
    }
  }
}