import _tool from '../tool'
import { SCRIPT_SRC_TYPE } from './const'
const BP_BUSINESS_LINE = 'business_platform'
const BC_BUSINESS_LINE = 'business_center'
const BASE_BUSINESS_LINE = 'base'
const businessLineList = [BP_BUSINESS_LINE, BC_BUSINESS_LINE]

const scripts = _tool.toArray(document.scripts)
const rootScript = scripts.filter(script => script.attributes['data-src-type'] && _tool.getAttr(script, 'data-src-type') === SCRIPT_SRC_TYPE)[0]
const curBusinessLine = rootScript && _tool.getAttr(rootScript, 'data-business-line')


export default {
  conf: require(`./${businessLineList.includes(curBusinessLine) ? curBusinessLine : BASE_BUSINESS_LINE}`),
  const: require('./const')
}