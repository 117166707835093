export default {
  primaryNavs: {
    home: '首页',
    extension: '推广',
    reports: '报表',
    property: '资产',
    finance: '财务',
    tool: '工具',
    school: '学堂'
  },
  secondaryNavs: {
    promotion_content: '推广内容',
    directed_data: '定向',
    creative_material: '创意素材',
    convert: '转化',
    account_tool: '账户工具',
    optimization_tool: '优化工具',
    design_tool: '设计工具',
    clue_management_tool: '线索管理工具',
    platform_tool: '平台工具'
  },
  thirdLevelNavs: {
    external: '落地页',
    DPA_product: 'DPA商品库',
    store: '门店',
    advertiser_package: '移动应用',
    audience_package: '定向包',
    dmp: '自定义人群包',
    interest_tags: '兴趣词定向词包',
    flow_packet: '穿山甲自定义流量包',
    basic_creative: '基础创意',
    additional_creative: '附加创意',
    convert_stat: '转化跟踪',
    opt_log: '操作日志',
    homepage_related: '主页关联',
    account_security: '账户信息与设置',
    msg_center: '消息中心',
    automate_rules: '自动规则',
    diagnosis: '诊断',
    tool_detector: '页面质量检测',
    bid_simulator: '竞价模拟器',
    creative_center: '巨量创意',
    stock_dfic: '图虫创意',
    market_v_home: '即合平台',
    star: '星图平台',
    site: '橙子建站',
    daren_cooperate: '达人视频授权',
    comment: '评论管理',
    im_xiao6: '小6客服',
    crm: '飞鱼CRM',
    clue: '青鸟线索通',
    creative_app: '巨量引擎APP',
    open_api: '商业开放平台',
    web_store: '商业服务市场'
  },
  sliderPrimaryNavs: {
    product: '商品库管理',
    behaviour: 'DPA自定义人群',
    material_library: '视频库',
    material_library_pic: '图片库',
    word_manage: '动态创意词包管理',
    playable: '试玩素材',
    homepage_related_hotsoon: '火山主页关联',
    homepage_related_awewe: '抖音主页关联',
    user: '用户',
    account: '账户',
    msg_list: '消息列表',
    msg_subscription: '消息订阅',
    diagnosis_account: '账户诊断',
    diagnosis_ad: '计划诊断',
    fund_manage: '资金管理',
    activity_coupon: '活动与赠款'
  },
  sliderSecondaryNavs: {
    account_security: '账号安全',
    user_manage: '用户管理',
    account_info: '账户信息',
    account_auth: '认证中心',
    account_receipt: '开票资质',
    account_majordomo: '账户管家',
    account_manage: '广告账户管理',
    cash_flow: '账户流水',
    recharge: '在线充值',
    refund: '在线退款',
    receipt: '自助开票',
    discount_activity: '活动列表',
    discount_coupon: '已获赠款'
  },
  common: {
    subtitle: '广告投放平台'
  }
}