import navI18n from 'Src/i18n/index.js'
import _tool from 'Src/tool'
import { defaultConfig, defaultSliderConfig } from './base.js'
import icon_msg from 'Src/assets/images/msg.svg'
import icon_msg_light from 'Src/assets/images/msg_light.svg'
import icon_no_msg from 'Src/assets/images/no_msg.svg'
import icon_no_msg_light from 'Src/assets/images/no_msg_light.svg'
import icon_phone from 'Src/assets/images/phone.svg'
import icon_phone_light from 'Src/assets/images/phone_light.svg'
import icon_question from 'Src/assets/images/question.svg'
import icon_question_light from 'Src/assets/images/question_light.svg'
import icon_new_tag from 'Src/assets/images/new_tag.svg'
import icon_hot_tag from 'Src/assets/images/hot.svg'
import icon_app_download_ad from 'Src/assets/images/app-download-ad-tip.svg'
import icon_app_download from 'Src/assets/images/app-download-tip.svg'
import icon_out_link from 'Src/assets/images/out_link.svg'
import icon_out_link_light from 'Src/assets/images/out_link_light.svg'
import icon_header_link_tips from 'Src/assets/images/header_link_tips.svg'
import {
  SCRIPT_SRC_TYPE,
  primaryNavs,
  secondaryNavs,
  thirdLevelNavs,
  sliderPrimaryNavs,
  sliderSecondaryNavs
} from './const'

const { protocol, host, pathname } = window.location
const isOnline = protocol.indexOf('https') > -1 || host.indexOf('test') > -1
const starUrl = isOnline ? 'https://star.toutiao.com/landing-page/adplatform' : 'https://star-test-dev.toutiao.com/'
const isLoginPage = pathname === '/pages/login/index.html'

let locale = _tool.queryToJson().locale || 'zh'
navI18n.use(locale)

const propertyChildren = {
  promotion_content: {
    ...secondaryNavs.promotion_content,
    children: {
      external: thirdLevelNavs.external,
      DPA_product: thirdLevelNavs.DPA_product,
      store: thirdLevelNavs.store,
      advertiser_package: thirdLevelNavs.advertiser_package
    }
  },
  directed_data: {
    ...secondaryNavs.directed_data,
    children: {
      audience_package: thirdLevelNavs.audience_package,
      dmp: thirdLevelNavs.dmp,
      interest_tags: thirdLevelNavs.interest_tags,
      flow_packet: thirdLevelNavs.flow_packet
    }
  },
  creative_material: {
    ...secondaryNavs.creative_material,
    children: {
      basic_creative: thirdLevelNavs.basic_creative,
      additional_creative: thirdLevelNavs.additional_creative
    }
  },
  convert: {
    ...secondaryNavs.convert,
    children: {
      convert_stat: thirdLevelNavs.convert_stat
    }
  }
}

const toolChildren = {
  account_tool: {
    ...secondaryNavs.account_tool,
    children: {
      opt_log: thirdLevelNavs.opt_log,
      homepage_related: thirdLevelNavs.homepage_related,
      account_security: thirdLevelNavs.account_security,
      msg_center: thirdLevelNavs.msg_center
    }
  },
  optimization_tool: {
    ...secondaryNavs.optimization_tool,
    children: {
      automate_rules: thirdLevelNavs.automate_rules,
      diagnosis: thirdLevelNavs.diagnosis,
      tool_detector: thirdLevelNavs.tool_detector,
      bid_simulator: thirdLevelNavs.bid_simulator
    }
  },
  design_tool: {
    ...secondaryNavs.design_tool,
    children: {
      creative_center: thirdLevelNavs.creative_center,
      stock_dfic: thirdLevelNavs.stock_dfic,
      market_v_home: thirdLevelNavs.market_v_home,
      star: thirdLevelNavs.star,
      site: thirdLevelNavs.site,
      daren_cooperate: thirdLevelNavs.daren_cooperate
    }
  },
  clue_management_tool: {
    ...secondaryNavs.clue_management_tool,
    children: {
      comment: thirdLevelNavs.comment,
      im_xiao6: thirdLevelNavs.im_xiao6,
      crm: thirdLevelNavs.crm,
      clue: thirdLevelNavs.clue
    }
  },
  platform_tool: {
    ...secondaryNavs.platform_tool,
    children: {
      creative_app: thirdLevelNavs.creative_app,
      open_api: thirdLevelNavs.open_api,
      web_store: thirdLevelNavs.web_store
    }
  }
}

_tool.merge(defaultConfig, {
  header_css: {
    'logo-container': {
      style: {
        margin: isLoginPage ? '0' : '0 0 0 32px'
      }
    }
  },
  extra_style_func: '请填写func, 否则不会生效',
  nav_type: {
    home: {
      ...primaryNavs.home
    },
    extension: {
      ...primaryNavs.extension
    },
    reports: {
      ...primaryNavs.reports
    },
    property: {
      ...primaryNavs.property,
      children: propertyChildren
    },
    tool: {
      ...primaryNavs.tool,
      children: toolChildren
    },
    finance: {
      ...primaryNavs.finance
    },
    school: {
      ...primaryNavs.school
    }
  },
  icon_type: {
    msg: {
      name: 'msg',
      svg: icon_no_msg,
      svgLight: icon_no_msg_light,
      renderModal: false,
      modalTabs: {},
      isActive: false,
      hidden: false
    },
    phone: {
      name: 'phone',
      svg: icon_phone,
      svgLight: icon_phone_light,
      renderModal: true,
      renderModalAppDownload: true,
      modalTabs: {},
      isActive: false,
      hidden: false
    },
    question: {
      name: 'question',
      svg: icon_question,
      svgLight: icon_question_light,
      renderModal: true,
      renderModalWXPlatform: true, // 渲染微信平台二维码
      renderModalAppDownload: false, // 渲染移动广告平台app二维码
      modalTabs: {
        todaySchool: {
          name: '今日学堂',
          link: '/school/?visitor_source=ad_web&location=header3',
          _blank: true,
          hot: true,
          hidden: false
        },
        commonQuestion: {
          name: '常见问题',
          link: '/academy/help/?article_id=6394259139505160194&visitor_source=ad_web&location=header4',
          _blank: true,
          hidden: false
        },
        feedback: {
          name: '意见反馈',
          link: '',
          _blank: true,
          hidden: false
        }
      },
      isActive: false,
      hidden: false
    }
  },
  i18n_type: {
    render: false,
    langs: {
      zh: '中文',
      en: 'English',
      // ja: '日本語'
    },
    hookFunc: '请填写func, 填写后不再自动写语言cookie'
  },
  avatar: {
    userModalTabs: {
      back2Manager: {
        name: '返回管理员平台',
        link: '/admin/godview/delete/',
        hidden: true
      },
      backToMajordomo: {
        name: '返回账户管家平台',
        link: '/admin/godview/delete/?next_url=/marco/view/index',
        hidden: true
      },
      isShowAdminAgent: {
        name: '',
        link: '/admin/godview/go_agent/',
        hidden: true
      },
      isShowAgent: {
        name: '',
        link: '/agent/delete_agency/',
        hidden: true
      },
      accountManager: {
        name: '账户管理',
        link: '/overture/account/info/',
        hidden: false
      },
      exit: {
        name: '退出',
        link: '/sso_logout/'
      }
    }
  },
  hidden_nav_highlight_urls: ['/pages/overview.html'],
  carousel_type: ['https://sf1-ttcdn-tos.pstatp.com/obj/ttfe/adfe/platform/Lark20191126-175550_1574762180037.png'],
  global_var_url: '/overture/account/context/',
  subtitle: navI18n.t('common.subtitle'),
  render_to: null,
  isLite: false,
  showLoginHtml: true, // 展示立即登录和注册按钮
  logo_href: '/pages/index.html' // ad后台目前跳转地址为 https://ad.oceanengine.com/pages/index.html
})

_tool.merge(defaultSliderConfig, {
  slider_type: {
    DPA_product: {
      name: thirdLevelNavs.DPA_product.name,
      mark: [],
      primary: {
        product: sliderPrimaryNavs.product,
        behaviour: sliderPrimaryNavs.behaviour
      }
    },
    basic_creative: {
      name: thirdLevelNavs.basic_creative.name,
      mark: [],
      primary: {
        material_library: sliderPrimaryNavs.material_library,
        material_library_pic: sliderPrimaryNavs.material_library_pic,
        word_manage: sliderPrimaryNavs.word_manage,
        playable: sliderPrimaryNavs.playable
      }
    },
    homepage_related: {
      name: thirdLevelNavs.homepage_related.name,
      mark: [],
      primary: {
        homepage_related_hotsoon: sliderPrimaryNavs.homepage_related_hotsoon,
        homepage_related_awewe: sliderPrimaryNavs.homepage_related_awewe
      }
    },
    msg_center: {
      name: thirdLevelNavs.msg_center.name,
      mark: [],
      primary: {
        msg_list: sliderPrimaryNavs.msg_list,
        msg_subscription: sliderPrimaryNavs.msg_subscription
      }
    },
    diagnosis: {
      name: thirdLevelNavs.diagnosis.name,
      mark: [],
      primary: {
        diagnosis_account: sliderPrimaryNavs.diagnosis_account,
        diagnosis_ad: sliderPrimaryNavs.diagnosis_ad
      }
    },
    user_account: {
      mark: [],
      primary: {
        user: {
          ...sliderPrimaryNavs.user,
          children: {
            account_security: sliderSecondaryNavs.account_security,
            user_manage: sliderSecondaryNavs.user_manage
          }
        },
        account: {
          ...sliderPrimaryNavs.account,
          children: {
            account_info: sliderSecondaryNavs.account_info,
            account_auth: sliderSecondaryNavs.account_auth,
            account_receipt: sliderSecondaryNavs.account_receipt,
            account_majordomo: sliderSecondaryNavs.account_majordomo,
            account_manage: sliderSecondaryNavs.account_manage
          }
        }
      }
    },
    fund_activity: {
      mark: [],
      primary: {
        fund_manage: {
          ...sliderPrimaryNavs.fund_manage,
          children: {
            cash_flow: sliderSecondaryNavs.cash_flow,
            recharge: sliderSecondaryNavs.recharge,
            refund: sliderSecondaryNavs.refund,
            receipt: sliderSecondaryNavs.receipt
          }
        },
        activity_coupon: {
          ...sliderPrimaryNavs.activity_coupon,
          children: {
            discount_activity: sliderSecondaryNavs.discount_activity,
            discount_coupon: sliderSecondaryNavs.discount_coupon
          }
        }
      }
    }
  }
})

export { defaultConfig, defaultSliderConfig, SCRIPT_SRC_TYPE, icon_msg, icon_msg_light, icon_no_msg, icon_no_msg_light, icon_phone, icon_phone_light, icon_question, icon_question_light, icon_new_tag, icon_hot_tag, icon_app_download_ad, icon_app_download, icon_out_link, icon_out_link_light, icon_header_link_tips }