import slider_icon_triangle_down from 'Src/assets/images/sliderbar/triangle_down.svg'
import _tool from '../tool'

const renderSingleLiDOM = (context, primary, key) => {
  const curObj = primary[key] || {}
  if (curObj.hidden) return
  const activeStatus = key === 'diagnosis_ad' || key === 'diagnosis_account'
    ? window.location.pathname === curObj.link
    : window.location.href.includes(curObj.link)

  let curLink = _tool.handleI18nLink(curObj.link)
  return `
    <li class="${context.CSS_NAMESPACE}-slider-list-li"
      data-active-li=${activeStatus}
      data-location-href=${curLink}>
      <div data-svg-light=false>${curObj.svg}</div>
      <div data-svg-light=true>${curObj.svgLight}</div>
      <span>${curObj.name}</span>
    </li>
  `.trim()
}

const renderMultiLiDOM = (context, primary, key) => {
  const curObj = primary[key] || {}
  if (curObj.hidden) return

  const marks = Object.keys(curObj.children).map(childKey => curObj.children[childKey].link)
  const isActiveContainer = marks.some(mark => window.location.href.includes(mark))
  const localOpenStatus = window.localStorage && window.localStorage.getItem(`common-navigator-slider-${key}`) === 'true'
  return `
    <li>
      <div class="${context.CSS_NAMESPACE}-slider-multi-container"
        data-slider-ul-id="${key}" data-open-status=${localOpenStatus || isActiveContainer} data-active-status=${isActiveContainer}>
        <div class="${context.CSS_NAMESPACE}-slider-multi-title-container">
          <div data-slider-primary-svg-light=false>${curObj.svg}</div>
          <div data-slider-primary-svg-light=true>${curObj.svgLight}</div>
          <span>${curObj.name}</span>
          <div data-slider-multi-triangle>${slider_icon_triangle_down}</div>
        </div>
        <ul class="${context.CSS_NAMESPACE}-slider-list-ul">
          ${Object.keys(curObj.children).map(childKey => {
            let secondaryObj = curObj.children[childKey]
            if (secondaryObj.hidden) return ''

            let curLink = _tool.handleI18nLink(secondaryObj.link)
            return `
              <li class="${context.CSS_NAMESPACE}-slider-multi-list-li"
                data-active-li=${window.location.href.includes(secondaryObj.link)}
                data-location-href=${curLink}>
                <span>${secondaryObj.name}</span>
              </li>
            `.trim()
          }).join('\n')}
        </ul>
      </div>
    </li>
  `.trim()
}

const renderDom = (context, { name, mark, primary }, curKey) => {
  const titleDom = name ? `
    <div class="${context.CSS_NAMESPACE}-slider-title-container">
      <span class="${context.CSS_NAMESPACE}-slider-title">${name}</span>
    </div>
  `.trim() : ''

  return `
    <div class="${context.CSS_NAMESPACE}-slider-container">
        ${ titleDom }
        <div class="${context.CSS_NAMESPACE}-slider-list-container">
          <ul class="${context.CSS_NAMESPACE}-slider-list-ul">
            ${Object.keys(primary).map(key => {
              const { children } = primary[key]
              return children ? `${renderMultiLiDOM(context, primary, key)}` : `${renderSingleLiDOM(context, primary, key)}`
            }).join('\n')}
          </ul>
        </div>
    </div>
  `.trim()
}

export const renderSliderBaseHtml = (context, curKey) => {
  const { slider_type } = context.sliderConfig
  return renderDom(context, slider_type[curKey], curKey)
}