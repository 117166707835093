import Configs from 'Src/configs'
import navI18n from 'Src/i18n/index.js'
import _tool from '../tool'
import { icon_logo, icon_user_info_triangle, icon_i18n_triangle } from 'Src/configs/const'
const {
  icon_hot_tag,
  icon_app_download_ad,
  icon_app_download,
  icon_out_link,
  icon_out_link_light,
  icon_header_link_tips
} = Configs.conf

const { DEFAULT_HEADER_TIPS } = Configs.const

let carousel_restrict = false

export const renderNavLiDOM = (obj, context, item) => {
  let curLink = _tool.handleI18nLink(obj.link)
  return `
    <li>
      <a href="${curLink}" ${obj._blank? `target="_blank"` : ''}
      data-link-tip=${obj.hint} data-link-tip-belong=${item}
      ${obj.log ? `data-log=${JSON.stringify(obj.log)}` : ''}>
        ${obj.name}
        ${obj.out
          ? `
            <span class="${context.CSS_NAMESPACE}-header-list-icon-out-link">${icon_out_link}</span>
            <span class="${context.CSS_NAMESPACE}-header-list-icon-out-link-light">${icon_out_link_light}</span>
            `
          : ''}
      </a>
    </li>
  `.trim()
}

export const renderPanelRight = (context) => {
  let carouselTypes = context.config.carousel_type
  const needCarousel = false && carouselTypes && carouselTypes.length > 1
  if (needCarousel && !carousel_restrict) {
    let firstCarouselUrl = carouselTypes[0]
    let lastCarouselUrl = carouselTypes[carouselTypes.length - 1]
    carouselTypes.unshift(lastCarouselUrl)
    carouselTypes.push(firstCarouselUrl)
    carousel_restrict = true
  }

  const carouselDom = `
    <div class="${context.CSS_NAMESPACE}-header-panel-right-container-list">
      ${
        carouselTypes
          .map((url, index) => `<img src="${carouselTypes[index]}" width="318" height="159">`)
          .join('\n')
      }
    </div>
  `.trim()
  const pictureDom = `
    <a href="https://ad.oceanengine.com/school/help?category_id=6763533770961190920" target="_blank">
      <img src="${context.config.carousel_type[0]}" width="318" height="159">
    <a>
  `.trim()
  return `
    <div class="${context.CSS_NAMESPACE}-header-panel-right">
      <div class="${context.CSS_NAMESPACE}-header-panel-right-container">
        ${ pictureDom }
      </div>
    </div>
  `.trim()
}

export const renderNavDropDown = (context, obj, item, index) => {
  if (!obj.children) return ''
  const columnCount = Object.keys(obj.children).filter(key => !obj.children[key].hidden).length

  return `
    <div class="${context.CSS_NAMESPACE}-header-nav-dropdown ${context.CSS_NAMESPACE}-header-nav-${item}" data-nav-dropdown-id=${item}>
      <div class="${context.CSS_NAMESPACE}-header-panel">
        <div class="${context.CSS_NAMESPACE}-header-panel-left">
          ${Object.keys(obj.children).map(key => {
            let secondaryObj = obj.children[key]
            if (secondaryObj.hidden) return ''
            return `
              <div class="${context.CSS_NAMESPACE}-header-list-${columnCount}">
                <div class="${context.CSS_NAMESPACE}-header-list-title">
                  <div class="${context.CSS_NAMESPACE}-header-list-title-icon">${secondaryObj.svg}</div>
                  <span>${secondaryObj.name}</span>
                </div>
                <ul class="${context.CSS_NAMESPACE}-header-list-ul" data-ul-id="${key}">
                  ${Object.keys(secondaryObj.children).map(childKey => {
                    let childObj = secondaryObj.children[childKey]
                    if (childObj.hidden) return ''
                    return `${renderNavLiDOM(childObj, context, item)}`
                  }).join('\n')}
                </ul>
              </div>
            `.trim()
          }).join('\n')}
        </div>
        ${renderPanelRight(context)}
      </div>
      <div class="${context.CSS_NAMESPACE}-header-link-tips">
        <div data-icon-link-tip>${icon_header_link_tips}</div>
        <span data-header-link-tips>${DEFAULT_HEADER_TIPS}</span>
      </div>
    </div>
  `.trim()
}

export const renderNavHtml = (context, filterArr = []) => {
  const { nav_type } = context.config
  const headerContainerDom = document.querySelector(`.${context.CSS_NAMESPACE}-header-container`)
  const keys = filterArr.length
    ? Object.keys(nav_type).filter(item => filterArr.includes(item))
    : Object.keys(nav_type)
  return keys.map((item, index) => {
    if (nav_type[item].hidden) return ''
    const navDropDownDom = `${renderNavDropDown(context, nav_type[item], item, index)}`
    headerContainerDom && _tool.render(headerContainerDom, navDropDownDom, 'beforeend')
    let curLink = _tool.handleI18nLink(nav_type[item].link)
    return `
      <div
        class="${context.CSS_NAMESPACE}-header-nav"
        data-nav-id="${item}"
        data-nav-active="${index === 0 && !filterArr.length}">
        <a class="${context.CSS_NAMESPACE}-header-nav-name"
          ${nav_type[item]._blank? `target="_blank"` : ''}
          data-nav-a-has-badge="false"
          data-nav-a-id="${item}"
          href="${curLink}">${nav_type[item].name}</a>
        ${nav_type[item].hasBadge ? `<div class="${context.CSS_NAMESPACE}-header-nav-a-badge"></div>` : ''}
      </div>
    `.trim()
  }).join('\n')
}

export const renderIconHtml = (context) => {
  let iconLists = context.config.icon_type
  return Object.keys(iconLists).map(item => {
    if (iconLists[item].hidden) return ''
    return `
      <div class="${context.CSS_NAMESPACE}-header-icon" data-icon-id="${item}" data-icon-active="${
        iconLists[item].isActive
      }">
        ${iconLists[item].renderModal
          ? `
            <div class="${context.CSS_NAMESPACE}-header-modal">
              ${Object.keys(iconLists[item].modalTabs).map(key => {
                let curObj = iconLists[item].modalTabs[key]
                if (curObj.hidden) return ''
                return `
                  <a class="${context.CSS_NAMESPACE}-header-tab" href="${curObj.link}"
                    ${curObj._blank? `target="_blank"` : ''} data-modal-tab-id="${key}">
                    <span>${curObj.name}</span>
                    ${curObj.hot
                      ? `${icon_hot_tag}`
                      : ''}
                  </a>
                `.trim()
              }).join('\n')}
              ${iconLists[item].renderModalWXPlatform
                ? `
                  <div class="${context.CSS_NAMESPACE}-header-app-download">
                    <div class="app-download-wxtip">微信公众号</div>
                    <img src="https://sf1-ttcdn-tos.pstatp.com/obj/ttfe/adfe/platform/new_ad_qrcode.png">
                    <div class="app-download-tip">${icon_app_download}</div>
                  </div>
                  `.trim()
                : ''}
              ${iconLists[item].renderModalAppDownload
                ? `
                  <div class="${context.CSS_NAMESPACE}-header-app-download ${context.CSS_NAMESPACE}-header-app-ad">
                    <div class="app-download-title">移动平台下载</div>
                    <img src="https://s0.pstatp.com/cdn/expire-1-h/byted-creative-app/QRCode/QRCode.png">
                    <div class="app-download-ad-tip">
                      <a href="https://ad.toutiao.com/academy/help/?category_id=6452154464005521422" target="_blank">了解详情</a>
                    </div>
                  </div>
                  `.trim()
                : ''}
            </div>
            `.trim()
          : ''}
        <div class="${context.CSS_NAMESPACE}-header-svg" data-svg-active="false">${iconLists[item].svg}</div>
        <div class="${context.CSS_NAMESPACE}-header-svg" data-svg-active="true">${iconLists[item].svgLight}</div>
      </div>
    `.trim()
  }).join('\n')
}

export const renderI18nHtml = (context) => {
  const { langs } = context.config.i18n_type
  const liDomLists = Object.keys(langs).map(key => `
    <li data-i18n-locale-name="${key}">${langs[key]}</li>
  `.trim()).join('\n')
  const locale = _tool.queryToJson().locale || 'zh'
  return `
      <span data-current-locale>${langs[locale]}</span>
      <div data-i18n-triangle-svg>${icon_i18n_triangle}</div>
    <div class="${context.CSS_NAMESPACE}-header-i18n-dropdown">
      <ul>
        ${liDomLists}
      </ul>
    </div>
  `.trim()
}

export const renderAvatarHtml = (context) => {
  return `
    <div class="${context.CSS_NAMESPACE}-header-avatar">
      ${context.config.avatar.svg}
    </div>
    <div class="${context.CSS_NAMESPACE}-header-user-container" data-icon-active="false">
      <div class="${context.CSS_NAMESPACE}-header-user-info" data-no-translate>
        <div class="${context.CSS_NAMESPACE}-header-user-account-name">${context.config.user_info.ad_name}</div>
        <div class="${context.CSS_NAMESPACE}-header-user-account-id">ID：${context.config.user_info.ad_id}</div>
      </div>
      <div class="${context.CSS_NAMESPACE}-header-user-info-triangle">${icon_user_info_triangle}</div>
      <div class="${context.CSS_NAMESPACE}-header-modal">
        ${
          context.config.isLite
            ? ''
            : `
              <div class="${context.CSS_NAMESPACE}-header-tab ${context.CSS_NAMESPACE}-header-not-hover">
                <div class="operator">操作者:</div>
                <div class="operator-name" data-no-translate>${context.config.user_info.core_user_name}</div>
              </div>
            `.trim()
        }
        ${Object.keys(context.config.avatar.userModalTabs).map(item => {
          let curObj = context.config.avatar.userModalTabs[item]
          if (curObj.hidden || curObj.name === '') return ''
          return `
            <a class="${context.CSS_NAMESPACE}-header-tab"
              href="${curObj.link}" ${curObj._blank ? `target="_blank"` : ''}>
              <span>${curObj.name}</span>
            </a>
          `.trim()
        }).join('\n')}
      </div>
    </div>
  `.trim()
}

export const renderBaseHtml = (context) => {
  return `
    <div class="${context.CSS_NAMESPACE}-header-container">
        <div class="${context.CSS_NAMESPACE}-header-logo-container">
          <a class="${context.CSS_NAMESPACE}-header-logo-icon" href="${context.config.logo_href}">${icon_logo}</a>
          <div class="${context.CSS_NAMESPACE}-header-login-verticle-line"></div>
        </div>
        <div class="${context.CSS_NAMESPACE}-header-text-container">
          <span class="${context.CSS_NAMESPACE}-header-title">${context.config.subtitle}</span>
        </div>
        <div class="${context.CSS_NAMESPACE}-header-nav-container ${context.CSS_NAMESPACE}-header-display-none"></div>
        <div class="${context.CSS_NAMESPACE}-header-icon-container ${context.CSS_NAMESPACE}-header-display-none"></div>
        <div class="${context.CSS_NAMESPACE}-header-i18n-container ${context.CSS_NAMESPACE}-header-display-none" data-no-translate></div>
        <div class="${context.CSS_NAMESPACE}-header-avatar-container ${context.CSS_NAMESPACE}-header-display-none"></div>
    </div>
  `.trim()
}

export const renderLoginHtml = (context) => {
  return `
    <div class="${context.CSS_NAMESPACE}-header-login-container">
      <div class="${context.CSS_NAMESPACE}-header-login-btn">立即登录</div>
      ${context.config.hideRegister ? '' : `<a class="${context.CSS_NAMESPACE}-header-register-btn" href="/self_service/" target="_blank">注册</a>`}
    </div>
  `.trim()
}