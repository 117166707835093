import _tool from './tool'
import { SCRIPT_SRC_TYPE } from 'Src/configs/const'
import navigatorInstance from './main'
const BP_BUSINESS_LINE = 'business_platform'

let scripts = _tool.toArray(document.scripts)
navigatorInstance.scope.rootScript = scripts.filter(script => script.attributes['data-src-type'] && _tool.getAttr(script, 'data-src-type') === SCRIPT_SRC_TYPE)[0]

if (navigatorInstance.scope.rootScript && _tool.getAttr(navigatorInstance.scope.rootScript, 'data-business-line') === BP_BUSINESS_LINE) {
  navigatorInstance.methods.getGlobalVarApi()
}

export { navigatorInstance }