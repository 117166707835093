import _tool from './tool'
import navI18n from 'Src/i18n/index.js'
import Configs from 'Src/configs'
import Promise from 'promise-polyfill'
import {
  renderNavDropDown,
  renderBaseHtml,
  renderNavHtml,
  renderIconHtml,
  renderI18nHtml,
  renderAvatarHtml,
  renderLoginHtml
} from './templates/header.html'
import { renderStyleCss } from './templates/style.css'
import {
  renderSliderBaseHtml
} from './templates/slider.html'

const {
  SCRIPT_SRC_TYPE,
  defaultConfig,
  defaultSliderConfig,
  icon_msg,
  icon_no_msg,
  icon_msg_light,
  icon_no_msg_light
} = Configs.conf

const { DEFAULT_HEADER_TIPS } = Configs.const

// 接口收敛常量
const GET_ACCOUNT_INFO_URL = '/overture/account/get_info/'
const GET_NEW_MSG_URL = '/notification/get_new_msg/'
const IGNORE_NEW_MSG_URL = '/notification/ignore_new_msg/'
const GET_SMB_DIRECT_REFUND = '/overture/account/show_smb_direct_refund/'
const GET_DISCOUNT_INFO = '/overture/account/show_discount_info/'
const GET_SHOW_RECHARGE = '/overture/account/show_recharge/'
const QUERY_SCHOOL_UPDATE_URL = '/academy/home/get_carousel_update_time/'

// 延迟消失配置项
const delayTime = 200
const delayVar = {}
const navHoverVar = {}

// bp业务线标识
const BP_BUSINESS_LINE = 'business_platform'


const main = () => {
  let context = {
    CSS_NAMESPACE: null,
    config: null,
    sliderConfig: null,
    script: null,
    getGlobalVarScript: null
  }
  let scope = {
    rootScript: null,
    globalVarPromise: null,
    showRechargePromise: null,
    loadNewMsgPromise: null,
    showDiscountInfoPromise: null,
    showFinanceRefundPromise: null,
    curSliderKey: ''
  }
  let $node = {
    headerRenderDom: null,
    sliderRenderDom: null,
    iconMsgObj: null
  }
  let actions = {
    relationalNavHighlight () {
      Array.prototype.forEach.call($node.allNavs, node => _tool.setAttr(node, 'data-nav-active', 'false'))
      let pathName = window.location.pathname
      if (context.config.hidden_nav_highlight_urls.some(url => pathName.includes(url))) {
        return
      }
      let isSelect = Object.keys(context.config.nav_type).some(nav => {
        let isInMarkList = context.config.nav_type[nav].mark.some(urlName => pathName.includes(urlName))
        if (isInMarkList) {
          let navSelected = Array.prototype.filter.call($node.allNavs, ele => {
            return _tool.getAttr(ele, 'data-nav-id') === nav
          })[0]
          _tool.setAttr(navSelected, 'data-nav-active', true)
          return true
        }
      })
      if (!isSelect) {
        _tool.setAttr($node.allNavs && $node.allNavs[0], 'data-nav-active', true)
      }
    },
    iconContainerEvents (event) {
      let ele = event.target || event.srcElement
      // 消息提醒 忽略按钮 触发事件
      if (_tool.getAttr(ele, 'class') === `${context.CSS_NAMESPACE}-header-ignore`) {
        methods.onIgnoreClick()
      }

      let isHtml = false
      while (ele.nodeName.toLowerCase() !== 'svg') {
        ele = ele.parentNode
        if (ele.nodeName && ele.nodeName === 'HTML') {
          isHtml = true
          break
        }
      }
      if (ele && !isHtml) {
        let iconObj = ele.parentNode.parentNode
        let iconId = _tool.getAttr(iconObj, 'data-icon-id')
        if (iconId === 'msg') {
          window.location.href = '/overture/account/notification/'
        } else {
          // actions.showRelationalModal(iconObj, 'iconList')
        }
        if (iconId === 'person') {
          window.open("https://star.toutiao.com/landing-page/adplatform", "_blank");
        }
      }
    },
    showRelationalModal (obj, type) {
      // 记录modal隐藏前的状态
      let status = obj && _tool.getAttr(obj, 'data-icon-active')
      // 隐藏全部modal
      Array.prototype.forEach.call($node.allIcons, item => _tool.setAttr(item, 'data-icon-active', false))
      // icon列表中不存在对应的modal时不处理点击的高亮效果
      if (type === 'iconList' && !obj.querySelector(`.${context.CSS_NAMESPACE}-header-modal`))
        return
      // 隐藏前若为非展示状态则进行展示
      if (status === 'false') {
        _tool.setAttr(obj, 'data-icon-active', true)
      }
    },
    go2login () {
      try {
        if (context.config.login_href) {
          window.location.href = context.config.login_href
        }
        if (loginInstance && loginInstance.context && loginInstance.context.CSS_NAMESPACE) {
          let loginType = _tool.getAttr(document.querySelector(`.${loginInstance.context.CSS_NAMESPACE}-toutiao-container`), 'data-login-type')
          let curInput = document.querySelector(`.${loginInstance.context.CSS_NAMESPACE}-toutiao-input-row[data-login-type=${loginType}] input`)
          curInput && curInput.focus()
        } else {
          // window.location.reload()
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleModalCollapsed (event) {
      try {
        let ele = event.target || event.srcElement
        let isHtml = ele && ele.nodeName === 'HTML'
        if (isHtml) {
          $node.allIcons && Array.prototype.forEach.call($node.allIcons, item => _tool.setAttr(item, 'data-icon-active', false))
          return
        }
        // 如果是svg对象 向上寻找父元素
        // 其他情况className是字符串时 寻找是否为icon-container和avatar-container
        while (
          _tool.objType(ele.className) !== 'String'
          || ! (
            ele.nodeName.toLowerCase() === 'div'
            && (
              ele.className.includes(`${context.CSS_NAMESPACE}-header-avatar-container`)
              || ele.className.includes(`${context.CSS_NAMESPACE}-header-icon-container`)
            )
          )
        ) {
          ele = ele.parentNode
          if (ele.nodeName && ele.nodeName === 'HTML') {
            isHtml = true
            break
          }
        }
        if (ele && !isHtml) {
          return
        }
        // 隐藏全部modal
        $node.allIcons && Array.prototype.forEach.call($node.allIcons, item => _tool.setAttr(item, 'data-icon-active', false))
      } catch (e) {
        console.log(e)
      }
    },
    navContainerEvents (event) {
      // 此处ele为a标签，所以通过nav的a标签的attr控制点击学堂后红点消失
      let ele = event.target || event.srcElement
      if (_tool.getAttr(ele, 'data-nav-a-id') === 'school') {
        _tool.setAttr(ele, 'data-nav-a-has-badge', false)
        // 记录点击的时间
        window.localStorage.setItem('LAST_CLICK_SCHOOL_DABGE',new Date().getTime())
      }
    },
    navMouseoverEvents (event) {
      let ele = event.target || event.srcElement
      const judgeFunc = (ele) => {
        return ele.nodeName.toLowerCase() === 'a' &&
          _tool.getAttr(ele, 'data-nav-a-id') &&
          _tool.getAttr(ele, 'data-nav-a-id') !== 'undefined'
      }
      while (ele && !judgeFunc(ele)) {
        ele = ele.parentNode
      }
      if (ele) {
        const type = _tool.getAttr(ele, 'data-nav-a-id')
        const navDropDownDom = $node.container.querySelector(`.${context.CSS_NAMESPACE}-header-nav-${type}`)
        if (navDropDownDom) {
          const allNavDropDownDoms = $node.container.querySelectorAll(`.${context.CSS_NAMESPACE}-header-nav-dropdown`)
          _tool.toArray(allNavDropDownDoms).forEach(dom => {
            _tool.removeClass(dom, `${context.CSS_NAMESPACE}-header-nav-dropdown-show`)
          })
        }
        _tool.addClass(navDropDownDom, `${context.CSS_NAMESPACE}-header-nav-dropdown-show`)
        navHoverVar[type] = true
      }
    },
    navMouseoutEvents (event) {
      let ele = event.target || event.srcElement
      const judgeFunc = (ele) => {
        return ele.nodeName.toLowerCase() === 'a' &&
          _tool.getAttr(ele, 'data-nav-a-id') &&
          _tool.getAttr(ele, 'data-nav-a-id') !== 'undefined'
      }
      while (ele && !judgeFunc(ele)) {
        ele = ele.parentNode
      }
      if (ele) {
        const type = _tool.getAttr(ele, 'data-nav-a-id')
        const navDropDownDom = $node.container.querySelector(`.${context.CSS_NAMESPACE}-header-nav-${type}`)
        setTimeout(() => {
          if (delayVar[type] || navHoverVar[type]) {
            return
          }
          _tool.removeClass(navDropDownDom, `${context.CSS_NAMESPACE}-header-nav-dropdown-show`)
        }, delayTime)
        navHoverVar[type] = false
      }
    },
    handleDropDownMouseEvents (event, toolEvtName) {
      let ele = event.target || event.srcElement
      const judgeFunc = (ele) => ele.nodeName.toLowerCase() === 'div' && _tool.hasClass(ele, `${context.CSS_NAMESPACE}-header-nav-dropdown`)
      while (ele && !judgeFunc(ele)) {
        ele = ele.parentNode
      }
      _tool[toolEvtName](ele, `${context.CSS_NAMESPACE}-header-nav-dropdown-show`)

      const type = _tool.getAttr(ele, 'data-nav-dropdown-id')
      if (type) {
        delayVar[type] = toolEvtName === 'addClass'
        const navDom = document.querySelector(`div.${context.CSS_NAMESPACE}-header-nav[data-nav-id=${type}]`)
        _tool[toolEvtName](navDom, `${context.CSS_NAMESPACE}-header-nav-dropdown-hover`)
      }
    },
    dropDownMouseoverEvents (event) {
      actions.handleDropDownMouseEvents(event, 'addClass')
    },
    dropDownMouseoutEvents (event) {
      actions.handleDropDownMouseEvents(event, 'removeClass')
    },
    thirdNavMouseoverEvents (event) {
      let ele = event.target || event.srcElement
      const judgeFunc = (ele) => {
        return ele.nodeName.toLowerCase() === 'a' &&
          _tool.getAttr(ele, 'data-link-tip') &&
          _tool.getAttr(ele, 'data-link-tip') !== 'undefined'
      }
      while (ele && !judgeFunc(ele)) {
        ele = ele.parentNode
      }
      if (ele) {
        const primaryNavName = _tool.getAttr(ele, 'data-link-tip-belong')
        const changeNode = document.querySelector(`.${context.CSS_NAMESPACE}-header-nav-${primaryNavName} .${context.CSS_NAMESPACE}-header-link-tips span[data-header-link-tips]`)
        changeNode.innerHTML = _tool.getAttr(ele, 'data-link-tip')
      }
    },
    thirdNavMouseoutEvents (event) {
      let ele = event.target || event.srcElement
      const judgeFunc = (ele) => {
        return ele.nodeName.toLowerCase() === 'a' &&
          _tool.getAttr(ele, 'data-link-tip') &&
          _tool.getAttr(ele, 'data-link-tip') !== 'undefined'
      }
      while (ele && !judgeFunc(ele)) {
        ele = ele.parentNode
      }
      if (ele) {
        const primaryNavName = _tool.getAttr(ele, 'data-link-tip-belong')
        const changeNode = document.querySelector(`.${context.CSS_NAMESPACE}-header-nav-${primaryNavName} .${context.CSS_NAMESPACE}-header-link-tips span[data-header-link-tips]`)
        changeNode.innerHTML = DEFAULT_HEADER_TIPS
      }
    },
    i18nContainerEvents (event) {
      let ele = event.target || event.srcElement
      const judgeFunc = (ele) => {
        return ele.nodeName.toLowerCase() === 'li' &&
          _tool.getAttr(ele, 'data-i18n-locale-name') &&
          _tool.getAttr(ele, 'data-i18n-locale-name') !== 'undefined'
      }
      while (ele && !judgeFunc(ele)) {
        ele = ele.parentNode
      }
      if (ele) {
        let existHooks
        const locale = _tool.getAttr(ele, 'data-i18n-locale-name')

        try {
          const { hookFunc } = context.config.i18n_type
          existHooks = _tool.objType(hookFunc) === 'Function'
          existHooks && hookFunc.call(this, locale)
        } catch (e) {console.log(e)}
        if (existHooks) {
          return
        }

        const curHostname = window.location.hostname
        const reg = /\.(\w+)\.(\w+)$/
        const res = curHostname.match(reg)
        const resDomain = res && res[0]
        document.cookie = `locale=${locale}; domain=${resDomain}; path=/`
        try {
          const tempId = _tool.queryToJson().temp_id || ''
          tempId && window.sessionStorage.removeItem(tempId)
        } catch (e) {
          console.log(e)
        }
        window.location.reload()
      }
    },
    sliderMultiTitleEvents (event) {
      let ele = event.target || event.srcElement
      const judgeFunc = (ele) => {
        return ele.nodeName.toLowerCase() === 'div' &&
          _tool.objType(ele.className) === 'String' &&
          ele.className.includes(`${context.CSS_NAMESPACE}-slider-multi-title-container`)
      }
      while(ele && !judgeFunc(ele)) {
        ele = ele.parentNode
      }
      if (ele) {
        let newStatus = !_tool.catchableParse(_tool.getAttr(ele.parentNode, 'data-open-status'))
        _tool.setAttr(ele.parentNode, 'data-open-status', newStatus)
        window.localStorage && window.localStorage.setItem(`common-navigator-slider-${_tool.getAttr(ele.parentNode, 'data-slider-ul-id')}`, newStatus)
      }
    },
    sliderContainerEvents (event) {
      let ele = event.target || event.srcElement
      const judgeFunc = (ele) => {
        return ele.nodeName.toLowerCase() === 'li' &&
          _tool.getAttr(ele, 'data-location-href')
      }
      while(ele && !judgeFunc(ele)) {
        ele = ele.parentNode
      }
      if (ele) {
        window.location = _tool.getAttr(ele, 'data-location-href')
        methods.extraHandlerSPA()
      }
    },
  }
  let methods = {
    initHeader (userConfig) {
      if (!scope.rootScript) return

      context.CSS_NAMESPACE = context.CSS_NAMESPACE || 'navigator-' + Math.random().toString(16).slice(2)
      context.config = JSON.parse(JSON.stringify(defaultConfig))
      _tool.merge(context.config, userConfig)

      $node.headerRenderDom = context.config.render_to && document.querySelector(context.config.render_to)
        ? document.querySelector(context.config.render_to)
        : document.body
      this.renderNodesEvents()
      _tool.getAttr(scope.rootScript, 'data-business-line') === BP_BUSINESS_LINE
        ? this.getGlobalVar() : this.fillContent()
    },
    initSliderBar (userConfig) {
      if (!scope.rootScript) return

      context.CSS_NAMESPACE = context.CSS_NAMESPACE || 'navigator-' + Math.random().toString(16).slice(2)
      context.sliderConfig = JSON.parse(JSON.stringify(defaultSliderConfig))
      _tool.merge(context.sliderConfig, userConfig)

      $node.sliderRenderDom = context.sliderConfig.render_to && document.querySelector(context.sliderConfig.render_to)
        ? document.querySelector(context.sliderConfig.render_to)
        : document.body

      this.handleSliderMarks()
      this.confirmSliderKey()
      this.renderSliderDom()
      this.bindSliderEvents()
      this.bindOnceSliderEvents()
    },
    handleSliderMarks () {
      const getChildrenLinks = (obj = {}) => {
        const { children = {} } = obj
        const len = Object.keys(children).length
        let result = []
        if (len) {
          Object.keys(children).forEach(key => {
            if (children[key].marks && children[key].marks.length) {
              result.push(...children[key].marks)
            } else if (children[key].link) {
              result.push(children[key].link)
            }
          })
        } else {
          result.push(obj.link)
        }
        return result
      }
      const { slider_type } = context.sliderConfig
      Object.keys(slider_type).forEach(item => {
        const { primary = {}, mark = [] } = slider_type[item]
        Object.keys(primary).forEach(key => {
          mark.push(...getChildrenLinks(primary[key]))
        })
      })
    },
    confirmSliderKey () {
      const { slider_type } = context.sliderConfig
      const curPath = window.location.href
      Object.keys(slider_type).some(item => {
        if (slider_type[item].mark.some(url => curPath.includes(url))) {
          scope.curSliderKey = item
          return true
        }
      })
    },
    renderSliderDom () {
      if (!scope.curSliderKey) return
      switch (scope.curSliderKey) {
        case 'basic_creative':
          scope.globalVarPromise
            .then(() => {
              // 基础创意-试玩素材
              if (_tool.isSupported(GLOBAL_VAR_API.playAble)) {
                const { basic_creative } = context.sliderConfig.slider_type
                basic_creative.primary.playable.hidden = false
              }
            })
            .catch(e => console.log(e))
            .finally(() => this.resetSliderRenderDomAndEvents())
          break
        case 'user_account':
          const { user, account } = context.sliderConfig.slider_type.user_account.primary
          scope.globalVarPromise
            .then(() => {
              // 账户信息与设置-开票资质
              if (GLOBAL_VAR_API.show_receipt_qualification) {
                account.children.account_receipt.hidden = false
              }
              // 账户信息与设置-认证中心
              if (
                (GLOBAL_VAR_API.auth_center_flag !== 2 && +GLOBAL_VAR_API.show_auth_center) ||
                GLOBAL_VAR_API.auth_center_flag === 2
              ) {
                account.children.account_auth.hidden = false
              }
            })
            .catch(e => console.log(e))
            .finally(() => this.resetSliderRenderDomAndEvents())
          break
        case 'fund_activity':
          const { fund_manage, activity_coupon } = context.sliderConfig.slider_type.fund_activity.primary
          scope.globalVarPromise.then(() => {
            // 资金管理-自助开票
            if (GLOBAL_VAR_API.show_self_receipt) {
              fund_manage.children.receipt.hidden = false
            }

            // 资金管理-在线充值
            scope.showRechargePromise = scope.showRechargePromise || this.getShowRecharge()
            scope.showRechargePromise.then(() => {
              if (_tool.catchableParse(GLOBAL_VAR_API.showRecharge)) {
                fund_manage.children.recharge.hidden = false
              }
            })

            // 资金管理-在线退款
            scope.showFinanceRefundPromise = this.showFinanceRefund()
            scope.showFinanceRefundPromise.then(() => {
              // 是否可以在线退款
              if (GLOBAL_VAR_API.showSmbDirectRefund || GLOBAL_VAR_API.show_self_refund) {
                fund_manage.children.refund.link = GLOBAL_VAR_API.show_self_refund
                  ? '/overture/self_refund/get_cg_self_refund_page/'
                  : '/overture/smb_direct/refund/'
                fund_manage.children.refund.hidden = false
              }
            })

            // 活动与赠款
            if (!_tool.catchableParse(GLOBAL_VAR_API.isLimitAccount)) {
              scope.showDiscountInfoPromise = this.showDiscountInfo()
              scope.showDiscountInfoPromise.then(() => {
                if (GLOBAL_VAR_API.show_discount_info) {
                  activity_coupon.hidden = false
                }
              })
            }

            Promise.all([scope.showRechargePromise, scope.showFinanceRefundPromise, Promise.resolve(scope.showDiscountInfoPromise)])
              .then(() => {})
              .catch(e => console.log(e))
              .finally(() => this.resetSliderRenderDomAndEvents())
          }).catch(e => this.resetSliderRenderDomAndEvents())
          break
        default:
          _tool.render($node.sliderRenderDom, renderSliderBaseHtml(context, scope.curSliderKey))
          break
      }
      _tool.render($node.sliderRenderDom, renderStyleCss(context, 'slider'))
    },
    bindSliderEvents () {
      _tool.actionListener(document.querySelector(`.${context.CSS_NAMESPACE}-slider-container`), 'click', actions.sliderMultiTitleEvents)
      _tool.actionListener(document.querySelector(`.${context.CSS_NAMESPACE}-slider-container`), 'click', actions.sliderContainerEvents)
    },
    bindOnceSliderEvents () {
      window.addEventListener('hashchange', (event = {}) => {
        methods.extraHandlerSPA()
      })
      window.addEventListener('popstate', (event = {}) => {
        methods.extraHandlerSPA()
      })
      var _wr = function (type) {
        var orig = history[type]
        return function () {
          var rv = orig.apply(this, arguments)
          var e = new Event(type)
          e.arguments = arguments
          window.dispatchEvent && window.dispatchEvent(e)
          return rv
        }
      }
      if (window.history) {
        history.pushState = _wr('pushState')
        history.replaceState = _wr('replaceState')
        window.addEventListener('replaceState', function(e) {
          methods.extraHandlerSPA()
        })
        window.addEventListener('pushState', function(e) {
          methods.extraHandlerSPA()
        })
      }
    },
    extraHandlerSPA () {
      const { slider_type } = context.sliderConfig

      let sliderLiDoms
      let multiContainerDoms

      this.confirmSliderKey()
      if (!scope.curSliderKey) return

      sliderLiDoms = document.querySelectorAll(`li[data-location-href]`)
      multiContainerDoms = document.querySelectorAll(`div.${context.CSS_NAMESPACE}-slider-multi-container`)

      if (!sliderLiDoms) return
      _tool.toArray(sliderLiDoms).forEach(liDom => {
        const liDomLink = _tool.getAttr(liDom, 'data-location-href')
        const liDomStatus = liDomLink && window.location.href.includes(liDomLink)
        _tool.setAttr(liDom, 'data-active-li', liDomStatus)
        if (liDomStatus && multiContainerDoms && multiContainerDoms.length) {
          _tool.toArray(multiContainerDoms).forEach(multiContainer => {
            _tool.setAttr(multiContainer, 'data-active-status', false)
          })
          _tool.setAttr(liDom.parentNode.parentNode, 'data-active-status', true)
        }
      })
    },
    resetSliderRenderDomAndEvents () {
      const sliderContainer = document.querySelector(`.${context.CSS_NAMESPACE}-slider-container`)
      sliderContainer && sliderContainer.remove()
      _tool.render($node.sliderRenderDom, renderSliderBaseHtml(context, scope.curSliderKey))
      this.bindSliderEvents()
    },
    renderNodesEvents () {
      _tool.render($node.headerRenderDom, renderBaseHtml(context))
      _tool.render($node.headerRenderDom, renderStyleCss(context))
      this.getNodes()
      this.bindEvents()
    },
    getNodes () {
      // nodesVariableRelationalMap节点变量映射关系
      // key值为$node中的变量名称, value值为节点className
      let nodesVariableRelationalMap = {
        container: `.${context.CSS_NAMESPACE}-header-container`,
        logoContainer: `.${context.CSS_NAMESPACE}-header-logo-container`,
        textContainer: `.${context.CSS_NAMESPACE}-header-text-container`,
        navContainer: `.${context.CSS_NAMESPACE}-header-nav-container`,
        iconContainer: `.${context.CSS_NAMESPACE}-header-icon-container`,
        i18nContainer: `.${context.CSS_NAMESPACE}-header-i18n-container`,
        avatarContainer: `.${context.CSS_NAMESPACE}-header-avatar-container`
      }
      Object.keys(nodesVariableRelationalMap).forEach(item => {
        if (!$node[item])
          $node[item] = document.querySelector(nodesVariableRelationalMap[item])
      })
    },
    getChildNodes () {
      // 获取二次渲染的时候的子节点
      let nodesVariableRelationalMap = {
        avatarUserContainer: `.${context.CSS_NAMESPACE}-header-user-container`,
        avatarUserIdSpan: `.${context.CSS_NAMESPACE}-header-user-container .${context.CSS_NAMESPACE}-header-user-info`
      }
      Object.keys(nodesVariableRelationalMap).forEach(item => {
        if (!$node[item])
          $node[item] = document.querySelector(nodesVariableRelationalMap[item])
      })
      $node.allNavs = document.querySelectorAll(`.${context.CSS_NAMESPACE}-header-nav`)
      $node.allIcons = $node.headerRenderDom.querySelectorAll('div[data-icon-active]')
    },
    bindEvents () {
      _tool.actionListener($node.iconContainer, 'click', actions.iconContainerEvents)
      _tool.actionListener($node.i18nContainer, 'click', actions.i18nContainerEvents)
      _tool.actionListener($node.navContainer, 'click', actions.navContainerEvents)
      // 一级导航hover事件
      _tool.actionListener($node.navContainer, 'mouseover', actions.navMouseoverEvents)
      _tool.actionListener($node.navContainer, 'mouseout', actions.navMouseoutEvents)
      // 三级导航hover事件
      _tool.actionListener($node.container, 'mouseover', actions.thirdNavMouseoverEvents)
      _tool.actionListener($node.container, 'mouseout', actions.thirdNavMouseoutEvents)
      // 导航dropdown hover事件
      _tool.actionListener($node.container, 'mouseover', actions.dropDownMouseoverEvents)
      _tool.actionListener($node.container, 'mouseout', actions.dropDownMouseoutEvents)
      _tool.actionListener(document, 'click', actions.handleModalCollapsed)
    },
    getGlobalVarApi () {
      let script = document.createElement('script')
      script.src = `/overture/account/context/`
      script.setAttribute('data-script-type', 'get-global-var')

      scope.globalVarPromise = new Promise((resolve, reject) => {
        script.onload = () => resolve()
        script.onerror = (e) => reject(e)
      })

      let container = document.head || document.documentElement
      container.appendChild(script)
    },
    fillContent () {
      if (context.config.loginStatus) {
        this.renderNavIconAvatar()
        this.getChildNodes()
        actions.relationalNavHighlight()
      } else {
        context.config.showLoginHtml && $node.container && _tool.render($node.container, renderLoginHtml(context), 'beforeend')
        _tool.actionListener($node.container.querySelector(`.${context.CSS_NAMESPACE}-header-login-btn`), 'click', actions.go2login)
        if (context.config.i18n_type.render) {
          _tool.removeClass($node.i18nContainer, `${context.CSS_NAMESPACE}-header-display-none`)
          _tool.render($node.i18nContainer, renderI18nHtml(context))
        }
      }
    },
    renderNavIconAvatar () {
      let $node2handle = {
        navContainer: renderNavHtml(context),
        iconContainer: renderIconHtml(context),
        avatarContainer: renderAvatarHtml(context)
      }
      // i18n多语言切换按钮
      if (window.GLOBAL_VAR_API && _tool.isSupported(GLOBAL_VAR_API.adMultiLanguage) || context.config.i18n_type.render) {
        $node2handle.i18nContainer = renderI18nHtml(context)
      }
      Object.keys($node2handle).forEach(item => {
        _tool.removeClass($node[item], `${context.CSS_NAMESPACE}-header-display-none`)
        _tool.render($node[item], $node2handle[item])
      })
    },
    getGlobalVar () {
      const onloadFunction = () => {
        if (GLOBAL_VAR_API.isLogin === 0) { //未登录状态
          context.config.showLoginHtml && $node.container && _tool.render($node.container, renderLoginHtml(context), 'beforeend')
          _tool.actionListener($node.container.querySelector(`.${context.CSS_NAMESPACE}-header-login-btn`), 'click', actions.go2login)
        } else if (GLOBAL_VAR_API.isLogin === 1) { //登录状态
          if (context.config.isLite) {
            this.handleLiteContext(context)
          } else {
            if (JSON.parse(GLOBAL_VAR_API.isAdmin)) {
              context.config.avatar.userModalTabs.back2Manager.hidden = false
            }
            if (JSON.parse(GLOBAL_VAR_API.permissions.backToMajordomo)) {
              context.config.avatar.userModalTabs.backToMajordomo.hidden = false
            }
            if (JSON.parse(GLOBAL_VAR_API.permissions.backToAdmin) &&
              JSON.parse(GLOBAL_VAR_API.permissions.backToAgent)) {
              context.config.avatar.userModalTabs.isShowAdminAgent.name = GLOBAL_VAR_API.advertiserAgent.name
              context.config.avatar.userModalTabs.isShowAdminAgent.hidden = false
            }
            if (!JSON.parse(GLOBAL_VAR_API.permissions.backToAdmin) &&
              JSON.parse(GLOBAL_VAR_API.permissions.backToAgent)) {
              context.config.avatar.userModalTabs.isShowAgent.name = GLOBAL_VAR_API.agent.name
              context.config.avatar.userModalTabs.isShowAgent.hidden = false
            }

            this.handleNavType(context.config.nav_type) // 处理白名单
            this.querySchoolUpdate() // 学堂小红点是否显示

            // 设置用户信息
            context.config.user_info.ad_name = GLOBAL_VAR_API.advertiser.name
            context.config.user_info.ad_id = GLOBAL_VAR_API.advertiser.id
            context.config.user_info.core_user_name = GLOBAL_VAR_API.coreUser.adminName
          }

          this.renderNavIconAvatar()
          this.getChildNodes()
          this.otherActions() // 处理其他操作
        }
      }
      scope.globalVarPromise.then(() => {
        onloadFunction()
      }).catch(e => console.log(e))
    },
    handleLiteContext (context) {
      Object.keys(context.config.avatar.userModalTabs).forEach(key => {
        if (key === 'exit') return
        context.config.avatar.userModalTabs[key].hidden = true
      })
      Object.keys(context.config.nav_type).forEach(key => {
        if (key === 'home') return
        if (key === 'finance') {
          context.config.nav_type[key].link = '/self_service/api/v1/pages/lite/refund.html'
          const children = context.config.nav_type[key].children['finance_title'].children
          Object.keys(children).forEach(item => {
            if (item === 'refund') {
              children[item].hidden = false
              children[item].link = '/self_service/api/v1/pages/lite/refund.html'
            } else {
              children[item].hidden = true
            }
          })
          return
        }
        context.config.nav_type[key].hidden = true
      })
      Object.keys(context.config.icon_type).forEach(key => {
        if (key === 'question') {
          context.config.icon_type[key].modalTabs['feedback'].hidden = true
          return
        }
        context.config.icon_type[key].hidden = true
      })
    },
    handleNavType (navType) {
      const { property, tool } = navType
      let diagnosis_id = 14

      // isLimitAccount 只留推广管理和工具箱以及账号管理，账号管理目前在人物下拉列表中
      if (_tool.catchableParse(GLOBAL_VAR_API.isLimitAccount)) {
        Object.keys(navType).forEach(item => {
          if (item === 'extension' || item === 'tool')
            return
          navType[item].hidden = true
        })
      }

      const { promotion_content, directed_data, convert } = property.children
      const { account_tool, optimization_tool } = tool.children

      // DPA商品库
      if (_tool.isSupported(GLOBAL_VAR_API.isDynamicProductLibrary)) {
        promotion_content.children.DPA_product.hidden = false
      }

      // 穿山甲流量包白名单
      if(_tool.isSupported(GLOBAL_VAR_API.flowPackage)) {
        directed_data.children.flow_packet.hidden = false
      }

      // 转化跟踪
      if (_tool.catchableParse(GLOBAL_VAR_API.showConvert)) {
        convert.children.convert_stat.hidden = false
      }

      // 操作日志
      if (!_tool.catchableParse(GLOBAL_VAR_API.isLimitAccount)) {
        account_tool.children.opt_log.hidden = false
      }

      // 诊断
      if (_tool.isSupported(diagnosis_id)) {
        optimization_tool.children.diagnosis.hidden = false
      }

      // 页面质量检测
      if (_tool.catchableParse(GLOBAL_VAR_API.showDetector)) {
        optimization_tool.children.tool_detector.hidden = false
      }

      // 竞价模拟器
      if (_tool.isSupported(GLOBAL_VAR_API.useSimulator)) {
        optimization_tool.children.bid_simulator.hidden = false
      }
    },
    otherActions () {
      // 根据GLOBAL_VAR_API.advertiser.id设置意见反馈的链接
      let feedbackElement = document.querySelector(`.${context.CSS_NAMESPACE}-header-tab[data-modal-tab-id=feedback]`)
      _tool.setAttr(feedbackElement, 'href', `/athena/index.html?key=2f905bc8aef3ddaf93c7755cd1ca47b4fc7e4d6ea7917e684ac3b3b384a67e82&business_id=${context.config.user_info.ad_id}#/`)

      if (!context.config.isLite) {
        scope.showRechargePromise = scope.showRechargePromise || this.getShowRecharge()
        scope.loadNewMsgPromise = this.loadNewMsg()
        Promise.all([scope.showRechargePromise, scope.loadNewMsgPromise])
          .then(dataArr => this.renderMsgDom(dataArr[1] || {}))
          .catch(e => console.log(e))
      }

      // 根据url高亮对应的nav
      actions.relationalNavHighlight()
    },
    /*
      影响范围: 消息列表-在线充值 & 导航-财务-在线充值
     */
    getShowRecharge () {
      return new Promise((resolve, reject) => {
        _tool.ajax('GET', {
          parse: true,
          headers: {
            'X-CSRFToken': GLOBAL_VAR_API.csrftoken
          },
          url: GET_SHOW_RECHARGE,
          success: (resp) => {
            if (resp && resp.code === 0 && resp.data) {
              GLOBAL_VAR_API.showRecharge = resp.data.show_recharge || false
            }
            resolve()
          },
          error: (e) => {
            reject(e)
          }
        })
      })
    },
    /*
      加载消息列表
     */
    loadNewMsg () {
      return new Promise((resolve, reject) => {
        _tool.ajax('GET', {
          parse: true,
          headers: {
            'X-CSRFToken': GLOBAL_VAR_API.csrftoken
          },
          url: GET_NEW_MSG_URL,
          success: (resp) => {
            resolve(resp)
          },
          error: (e) => {
            reject(e)
          }
        })
      })
    },
    // 渲染消息列表下面的dom
    renderMsgDom ({ has_new, new_msg }) {
      if (has_new > 0 && new_msg.length) {
        $node.iconMsgObj = Array.prototype.filter.call($node.allIcons, icon => {
          return _tool.getAttr(icon, 'data-icon-id') === 'msg'
        })[0]
        let text = `
        <div class="${context.CSS_NAMESPACE}-header-modal" style="width: 250px; left: -204px;">
          <div style="width: 100%; max-height: 340px; overflow-y: auto;">
            ${new_msg && new_msg.map(item => {
              // item.type === 501 广告主审核通过 accountState.notificationType.ADV_AUDIT_SUCCESS: 501  (common/state/account.js)
              return `
                  <div class="${context.CSS_NAMESPACE}-header-msg-tab">
                    <a href="/overture/account/notification/">${item.title}</a>
                    ${item.type === 501 && _tool.catchableParse(GLOBAL_VAR_API.showRecharge)
                      ? '<a href="/overture/cash/recharge/" style="color: #5d7ca5" data-log={"type":"recharge-from","title":"已审核-消息-立即充值","delay":true}>立即充值</a>'
                      : ''}
                    <br/>
                    <span>${item.create_time}</span>
                  </div>
              `.trim()
            }).join('\n')}
          </div>
          <div class="${context.CSS_NAMESPACE}-header-options">
            <a href="/overture/account/notification/">所有消息</a>
            <span class="${context.CSS_NAMESPACE}-header-ignore">忽略</span>
          </div>
        </div>
        `.trim()
        if ($node.iconMsgObj && $node.iconMsgObj.querySelectorAll('div[data-svg-active]').length) {
          $node.iconMsgObj.querySelector('div[data-svg-active="false"]').innerHTML = `${icon_msg}`
          $node.iconMsgObj.querySelector('div[data-svg-active="true"]').innerHTML = `${icon_msg_light}`
          _tool.setAttr($node.iconMsgObj, 'data-icon-active', true)
          _tool.render($node.iconMsgObj, text, 'beforeend')
        }
      }
    },
    showDiscountInfo () {
      return new Promise((resolve, reject) => {
        _tool.ajax('GET', {
          parse: true,
          headers: {
            'X-CSRFToken': GLOBAL_VAR_API.csrftoken
          },
          url: GET_DISCOUNT_INFO,
          success: (resp) => {
            if (resp && resp.code === 0 && resp.data) {
              GLOBAL_VAR_API.show_discount_info = resp.data.show_discount_info || false
            }
            resolve()
          },
          error: (e) => {
            reject(e)
          }
        })
      })
    },
    showFinanceRefund () {
      return new Promise((resolve, reject) => {
        _tool.ajax('GET', {
          parse: true,
          headers: {
            'X-CSRFToken': GLOBAL_VAR_API.csrftoken
          },
          url: GET_SMB_DIRECT_REFUND,
          success: (resp) => {
            if (resp && resp.code === 0 && resp.data) {
              GLOBAL_VAR_API.showSmbDirectRefund = resp.data.show_smb_direct_refund || false
            }
            resolve()
          },
          error: (e) => {
            reject(e)
          }
        })
      })
    },
    onIgnoreClick () {
      _tool.ajax('POST', {
        parse: true,
        headers: {
          'X-CSRFToken': GLOBAL_VAR_API.csrftoken
        },
        url: IGNORE_NEW_MSG_URL,
        success: (resp) => {
          if ($node.iconMsgObj && $node.iconMsgObj.querySelectorAll('div[data-svg-active]').length) {
            let msgModal = $node.iconMsgObj.querySelector(`.${context.CSS_NAMESPACE}-header-modal`)
            $node.iconMsgObj.querySelector('div[data-svg-active="false"]').innerHTML = `${icon_no_msg}`
            $node.iconMsgObj.querySelector('div[data-svg-active="true"]').innerHTML = `${icon_no_msg_light}`
            _tool.setAttr($node.iconMsgObj, 'data-icon-active', false)
            msgModal && msgModal.remove()
          }
        }
      })
    },
    // 查询学堂最近更新的时间
    querySchoolUpdate () {
      _tool.ajax('GET', {
         parse: true,
         headers: {
           'X-CSRFToken': GLOBAL_VAR_API.csrftoken
         },
         url: QUERY_SCHOOL_UPDATE_URL,
         success: (resp) => {
           //更新时间和上次点击红点消失的时间比较
          if (resp.code === 0 && resp.update_time) {
            const new_date = new Date(Date.parse(resp.update_time.replace(/-/g,'/'))).getTime()
            const last_date = window.localStorage.getItem('LAST_CLICK_SCHOOL_DABGE')
            const schoolNav = $node.navContainer.querySelector('a[data-nav-a-id="school"]')
            _tool.setAttr(schoolNav, 'data-nav-a-has-badge', new_date && new_date > last_date)
          }
         }
       })
    }
  }
  return { context, scope, $node, methods, actions, renderNavHtml }
}
export default main()