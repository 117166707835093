import navI18n from 'Src/i18n/index.js'
import _tool from 'Src/tool'
import icon_avater from 'Src/assets/images/avatar.svg'
let locale = _tool.queryToJson().locale || 'zh'
navI18n.use(locale)

export const defaultConfig = {
  header_css: {
    container: {
      style: {
        display: 'flex',
        width: '100%',
        height: '64px',
        background: '#0A2140',
        position: 'relative',
        'box-shadow': '0px 2px 11px 0px rgba(0,0,0,0.03)',
        'font-size': '0',
        'min-width': '1280px'
      }
    },
    'logo-container': {
      style: {
        display: 'inline-block',
        width: '32px',
        margin: '0 0 0 32px',
        position: 'relative'
      }
    },
    'text-container': {
      style: {
        display: 'inline-block',
        height: '100%',
        color: '#FFFFFF',
        opacity: '0.8',
        'font-size': '16px',
        'margin-left': '30px',
        'font-family': 'PingFangSC-Regular',
        'font-weight': '400',
        'white-space': 'nowrap',
        'line-height': '64px',
        'vertical-align': 'top'
      }
    },
    'nav-container': {
      style: {
        height: '100%',
        'margin-left': '30px',
        'vertical-align': 'top',
        'flex': 1
      }
    },
    'icon-container': {
      style: {
        width: '132px',
        height: '100%',
        display: 'flex',
        position: 'relative',
        'justify-content': 'space-between',
        'align-items': 'center',
        'user-select': 'none',
        'margin-right': '12px'
      },
      ':after': {
        width: '1px',
        height: '24px',
        position: 'absolute',
        right: '-12px',
        top: '20px',
        background: '#999',
        'border-radius': '2px'
      }
    },
    'i18n-container': {
      style: {
        width: '100px',
        height: '100%',
        display: 'flex',
        position: 'relative',
        cursor: 'pointer',
        'align-items': 'center',
        'justify-content': 'center',
        'user-select': 'none'
      },
      ':after': {
        width: '1px',
        height: '24px',
        position: 'absolute',
        right: '0px',
        top: '20px',
        background: '#999',
        'border-radius': '2px'
      }
    },
    'avatar-container': {
      style: {
        height: '100%',
        'user-select': 'none',
        'margin-left': '24px',
        'margin-right': '26px'
      }
    },
  },
  nav_type: {},
  icon_type: {},
  i18n_type: {},
  avatar: {
    svg: icon_avater,
    userModalTabs: {}
  },
  user_info: {
    ad_name: '',
    ad_id: '',
    core_user_name: ''
  },
  hidden_nav_highlight_urls: [],
  global_var_url: '/overture/account/context/',
  subtitle: navI18n.t('common.subtitle'),
  render_to: null,
  isLite: false,
  showLoginHtml: true, // 展示立即登录和注册按钮
  login_href: '', // 立即登录跳转
  logo_href: '' // logo跳转地址
}

export const defaultSliderConfig = {
  slider_css: {
    container: {
      style: {
        width: '200px',
        height: 'calc(100vh - 64px)',
        background: '#FFFFFF',
        'font-size': '0',
        'box-shadow': '0px 2px 4px rgba(0, 0, 0, 0.146937)'
      }
    },
    'title-container': {
      style: {
        padding: '17px 0',
        margin: '0 16px',
        'box-sizing': 'content-box',
        'border-bottom': '1px solid #E4E9ED',
        'margin-bottom': '12px'
      }
    },
    'list-container': {
      style: {
        width: '100%'
      }
    }
  },
  extra_style_func: '请填写func, 否则不会生效',
  render_to: null,
  slider_type: {}
}