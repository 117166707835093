export default {
  primaryNavs: {
    home: 'Dashboard',
    extension: 'Ads Manager',
    reports: 'Reporting',
    property: 'Library',
    finance: 'Finance',
    tool: 'Toolbox',
    school: 'Help Center'
  },
  secondaryNavs: {
    promotion_content: 'Campaign Destination',
    directed_data: 'Targeting',
    creative_material: 'Creative',
    convert: 'Conversion',
    account_tool: 'Account',
    optimization_tool: 'Efficiency Tool',
    design_tool: 'Designing',
    clue_management_tool: 'Leads Management',
    platform_tool: 'Platform'
  },
  thirdLevelNavs: {
    external: 'Landing Page',
    DPA_product: 'DPA Product Catalogue',
    store: 'Store Locations',
    advertiser_package: 'App Install',
    audience_package: 'Targeting Settings',
    dmp: 'Audience Settings',
    interest_tags: 'Interests Tags Settings',
    flow_packet: 'Pangle Ad Placement',
    basic_creative: 'Essential Creative',
    additional_creative: 'Additional Creative',
    convert_stat: 'Conversion Tracking',
    opt_log: 'Change Log',
    homepage_related: 'Account Binding',
    account_security: 'Account Info & Settings',
    msg_center: 'Notification',
    automate_rules: 'Automated Rule',
    diagnosis: 'Diagnosis',
    tool_detector: 'Site Quality Analysis',
    bid_simulator: 'Bidding Simulator',
    creative_center: 'Creative Hub',
    stock_dfic: 'Tuchong Photo',
    market_v_home: 'Material Platform',
    star: 'Creator Marketplace',
    site: 'Chengzi Site Builder',
    daren_cooperate: 'Creator Authorized Video',
    comment: 'Comments Management',
    im_xiao6: 'Xiao6 Customer Service',
    crm: 'Flying Fish CRM',
    clue: 'Qingniao Leads',
    creative_app: 'Ocean Engine APP',
    open_api: 'Marketing API',
    web_store: 'Marketplace'
  },
  sliderPrimaryNavs: {
    product: 'Catalogue Management',
    behaviour: 'DPA Audience',
    material_library: 'Videos',
    material_library_pic: 'Images',
    word_manage: 'Dynamic Keywords Feed ',
    playable: 'Playable Material',
    homepage_related_hotsoon: 'Huoshan Account Binding',
    homepage_related_awewe: 'Douyin Account Binding',
    user: 'User',
    account: 'Account',
    msg_list: 'All Notifications',
    msg_subscription: 'Subscription Settings',
    diagnosis_account: 'Account Diagnosis',
    diagnosis_ad: 'Ad Diagnosis',
    fund_manage: 'Transaction',
    activity_coupon: 'activity & coupon'
  },
  sliderSecondaryNavs: {
    account_security: 'Security',
    user_manage: 'Account Info',
    account_info: 'Account Info & Settings',
    account_auth: 'Authentication',
    account_receipt: 'Invoice Info',
    account_majordomo: 'Account Manager',
    account_manage: 'Account Info Management',
    cash_flow: 'Billing',
    recharge: 'Top Up',
    refund: 'Refund',
    receipt: 'Invoice',
    discount_activity: 'Activities',
    discount_coupon: 'Coupons'
  },
  common: {
    subtitle: 'Ads Platform'
  }
}