import { defaultConfig, defaultSliderConfig } from './base.js'
import _tool from 'Src/tool'

_tool.merge(defaultConfig, {
  i18n_type: {
    render: false,
    langs: {
      zh: '中文',
      en: 'English',
      // ja: '日本語'
    },
    hookFunc: '请填写func, 填写后不再自动写语言cookie'
  },
  loginStatus: false,
  hideRegister: true
})

export {
  defaultConfig,
  defaultSliderConfig
}